import axios from '@/helpers/axios'
import { ToastSuccess, ToastError, ToastGen, toastCatch, ToastDanger } from '@/helpers/toasts'
import { useCurrentUserStore } from '@/stores/current-user.store'

export * as prepay from './prepay.api'

export type CardDto = {
    id: number
    arendator_id?: number
    code: string
}

export enum CardType {
    Access = 'ACCESS',
    Dispensers = 'DISPENSERS',
    Prepay = 'PREPAY',
    Limit = 'LIMIT',
}

export interface Card {
    id: number
    code: string
    block_state: 'TRUE' | 'FALSE' | 'LIMIT'
    group_id: number
    state: 'AWAY' | 'ON_PARKING' | 'UNKNOWN'
    owner: string
    money: number
    change_time: string
    enter_time: string
    deleted: 'FALSE' | 'TRUE'
    valid_time: string
    contract_num: number
    photo_id: number
    tabel_num: string
    type: CardType
    park_num: string | null
    comments: string
    tariff: number
    free_exit_count: number
    GroupName: string | null
    arendator_id: number | null
    arendator_name: string | null
}

export interface AccessCardsCounters {
    on_parking: number
    away: number
    unknown: number
}

export type AccessCardListDto = CardListDto<Card> & {
    count: AccessCardsCounters
}

export interface CardListRequest {
    card_type?: CardType
    sort_by?: keyof Card
    order_by?: 'ASC' | 'DESC'
    search?: string
    deleted?: 'true' | 'false'
    page?: number
    limit?: number
}

export interface CardListDto<C extends Card> {
    count: any
    list: C[]
}

export async function getCardsList<R extends CardListDto<C>, C extends Card>(req: CardListRequest | null = null): Promise<R> {
    try {
        const res = await axios.get('/api/cards', {
            params: req ? req : {},
        })
        if (res.status == 200 && !res.data.error) {
            return res.data
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return null
}

export async function addCard(card: CardDto): Promise<boolean> {
    try {
        const res = await axios.post('/api/cards', card)
        if (res.status == 200 && !res.data.error) {
            ToastSuccess(res.data.message)
            return true
        }

        ToastError(res)
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }
    return false
}

// Второй аргумент можно заменить генериком
export async function saveCard(card: CardDto, card_type: CardType | null = null) {
    try {
        const cardDto = Object.assign({}, card)
        // Если текущий юзер не админ, то не устанавливаем arendator_id
        const currentUserStore = useCurrentUserStore()
        if (!currentUserStore.isAdmin) {
            delete cardDto.arendator_id
        }

        const res = await axios.put(`/api/cards/${card.id}`, cardDto, {
            params: { card_type },
        })
        if (res.status == 200) {
            ToastSuccess(`Карта ${card.code} сохранена`)
            return true
        }

        ToastError(res)
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }
    return false
}

export async function softDeleteCard(card: CardDto, card_type: CardType | null = null): Promise<boolean> {
    try {
        const res = await axios.put(
            `/api/cards/${card.id}`,
            {
                deleted: 'TRUE',
            },
            { params: { card_type } },
        )
        if (res.status == 200) {
            ToastGen(`Карта ${card.code} помечена как удаленная`).bodyClass('info').create()
            //this.list.splice(this.list.indexOf(card), 1)
            return true
        }

        ToastError(res)
    } catch (ex) {
        console.error(ex)
        ToastError('Не удалось пометить карту удаленной')
    }
    return false
}

export async function restoreCard(card: CardDto, card_type: CardType | null = null) {
    try {
        const res = await axios.put(
            `/api/cards/${card.id}`,
            {
                deleted: 'FALSE',
            },
            { params: { card_type } },
        )
        if (res.status == 200) {
            ToastSuccess(`Карта ${card.code} восстановлена`)
            //this.list.splice(this.list.indexOf(card), 1)
            return true
        }

        ToastError(res)
    } catch (ex) {
        console.error(ex)
        ToastError('Не удалось восстановить карту')
    }
    return false
}

export async function deleteCard(card: CardDto, card_type: CardType | null = null) {
    try {
        const params = {
            forced: true,
            card_type,
        }

        const res = await axios.delete(`/api/cards/${card.id}`, { params })
        if (res.status == 200) {
            ToastDanger(`Карта ${card.code} полностью удалена`)
            //this.list.splice(this.list.indexOf(card), 1)
            return true
        }

        ToastError(res)
    } catch (ex) {
        console.error(ex)
        ToastError('Не удалось полностью удалить карту')
    }

    return false
}

export async function sendAllCards() {
    try {
        ToastGen(`Команда "Список карт доступа" добавлена в очередь отправки.`).bodyClass('info').create()

        await axios.post(`/api/cards/send-all`)
    } catch (ex) {
        console.error(ex)
    }
}

export interface CardMoveHistory {
    id: number
    time: string
    card_code: string
    event: 'IN' | 'OUT' | 'WRITE_OFF'
    photo_id: number
    sector: number
    terminal_id: number
}

export function getCardHistory(cardId: number): Promise<CardMoveHistory[]> {
    return toastCatch(async () => {
        const res = await axios.get(`/api/cards/${cardId}/history`)
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return res.data
    })
}

export interface CardOwnerPhoto {
    photo_uri: string
    time: string
}

export function getCardOwnerPhoto(cardId: number): Promise<CardOwnerPhoto> {
    return toastCatch(async () => {
        const res = await axios.get(`/api/cards/${cardId}/photo`)
        if (res.status != 200 || res.data.error) {
            throw new Error(res.data.error ?? res.status)
        }

        return res.data
    })
}
