import api from '@/api'
import {type Tariff } from '@/api/tariffs'
import { defineStore } from 'pinia'

export type TariffsState = {
    list: Tariff[] | null
}

export const useTariffsStore = defineStore('tariffs', {
    state(): TariffsState {
        return {
            list: null,
        }
    },

    actions: {
        async load() {
            const list = await api.tariffs.getTariffs()
            if (list) {
                this.list = list
            }
        },
    },
})
