import axios from '@/helpers/axios'
import { ToastError } from '@/helpers/toasts'

export interface WorkTime {
    parking_start_time: string
    parking_end_time: string
    tickets_start_time: string
    tickets_end_time: string
    cards_start_time: string
    cards_end_time: string
}

export async function getWorkTime(): Promise<WorkTime | null> {
    try {
        const res = await axios.get('/api/tariffs/worktime')
        if (res.status == 200) {
            return res.data
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return null
}

export async function setWorkTime(worktime: WorkTime): Promise<boolean> {
    try {
        const res = await axios.get('/api/tariffs/worktime')
        if (res.status == 200) {
            return true
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return false
}
