import { createApp } from 'vue'
import { createPinia } from 'pinia'

import { createRoutes } from './routes'
import App from './App.vue'
import LoadingGear from './components/LoadingGear.vue'
import Modal from './components/Modal.vue'
import Pagination from './components/Pagination.vue'

createApp(App)
    // Плагины
    .use(createRoutes())
    .use(createPinia())

    // Компоненты доступные везде
    .component('LoadingGear', LoadingGear)
    .component('Modal', Modal)
    .component('Pagination', Pagination)

    .mount('#main_app')
