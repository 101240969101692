import api from '@/api'
import { type User } from '@/api/users'
import { defineStore } from 'pinia'

export type UsersState = {
    list: User[]
}

export const useUsersStore = defineStore('users', {
    state(): UsersState {
        return {
            //todo: current user
            list: [],
        }
    },
    actions: {
        async load() {
            this.list = await api.users.list() ?? []
        },
    },
})
