<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { Card, CardOwnerPhoto } from '@/api/cards'
import api from '@/api'

interface Props {
    card: Card
}

const props = defineProps<Props>()

const photo = ref<CardOwnerPhoto>()
onMounted(async () => {
    photo.value = await api.cards.getCardOwnerPhoto(props.card.id)
})
</script>

<template>
    <div>
        <a v-if="photo?.photo_uri" :href="`/var/photoscard/${photo.photo_uri}`" target="_blank">
            <img :src="`/var/photoscard/${photo.photo_uri}`" alt="Фото не найдено" height="270" class="img-thumbnail" />
        </a>
        <p v-else class="text-center">Фото не найдено</p>
    </div>
</template>
