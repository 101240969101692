<script setup lang="ts">
import { computed, ref } from 'vue'

interface Props {
    disabled?: boolean
}
const props = withDefaults(defineProps<Props>(), { disabled: false })

type Model = 'UNKNOWN' | 'ON_PARKING' | 'AWAY'

const model = defineModel<Model>()
</script>

<template>
    <i
        class="fa-regular fs-3"
        :class="{
            'fa-square-question': model == 'UNKNOWN',
            'fa-square-parking': model == 'ON_PARKING',
            'fa-square-parking-slash': model == 'AWAY',
        }"
        :style="{
            cursor: props.disabled ? 'not-allowed' : 'pointer',
        }"
        @click="
            () => {
                if (disabled) return

                if (model == 'UNKNOWN') {
                    model = 'ON_PARKING'
                } else if (model == 'ON_PARKING') {
                    model = 'AWAY'
                } else if (model == 'AWAY') {
                    model = 'UNKNOWN'
                }
            }
        "
    ></i>
</template>
