<script setup lang="ts">
import { useCurrentUserStore } from '@/stores/current-user.store';
import { ref } from 'vue'
const props = defineProps(['item', 'commands'])
const emit = defineEmits(['delete'])

const currentUserStore = useCurrentUserStore()
const canWrite = ref(currentUserStore.has('pages.admin_main/parker_scheduler.write'))
</script>

<template>
    <li class="list-group-item ap-list-item card tariff-card mb-3">
        <div class="text-end">
            <button type="button" class="btn-close" aria-label="Close" @click="$emit('delete')" :disabled="!canWrite"></button>
        </div>

        <div class="row">
            <div class="col">
                <div class="form-text">Команда</div>
                <select class="form-select" v-model="props.item.command" :disabled="!canWrite">
                    <option v-for="(cmd, id) in props.commands" :value="id">[{{ id }}] {{ cmd }}</option>
                </select>
            </div>

            <div class="w-100"></div>

            <div class="col">
                <div class="form-text">Параметры</div>
                <input type="text" class="form-control" v-model="props.item.args" :disabled="!canWrite" />
            </div>

            <div class="col">
                <div class="form-text">Режим</div>
                <select class="form-control" v-model="props.item.mode" :disabled="!canWrite">
                    <option value="days">Время суток</option>
                    <option value="minutes">Каждые N минут</option>
                    <option value="seconds">Каждые N секунд</option>
                </select>
            </div>

            <div class="col">
                <div class="form-text">Значение</div>
                <input :disabled="!canWrite" :type="props.item.mode == 'days' ? 'time' : 'number'" class="form-control" v-model="props.item.value" />
            </div>
        </div>
    </li>
</template>

<style lang="scss">
[data-bs-theme='light'] .tariff-card {
    background-color: #fed7aa;
}

[data-bs-theme='dark'] .tariff-card {
    background-color: #212529;
}
</style>
