import axios from '@/helpers/axios'
import { toastCatch } from '@/helpers/toasts'

// Легаси-страницы
export interface LegacyPage {
    name: string
    link: string
    filename?: boolean
}

//export type Pages extends Record<>

export function getPages(): Promise<LegacyPage[]> {
    return toastCatch(async () => {
        const res = await axios.get('/api/arendators')
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return res.data
    })
}
