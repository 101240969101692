import axios from '@/helpers/axios'
import { ToastError } from '@/helpers/toasts'

export interface SchedulerStatus {
    status: 'running' | 'failure'
    version: string
}

export async function getStatus(): Promise<SchedulerStatus | null> {
    try {
        const res = await axios.get('/api/parker/scheduler')
        if (res.status != 200) {
            throw new Error('Не удалось получить состояние планировщика')
        }

        return res.data
    } catch (ex) {
        console.error(ex)
        ToastError(ex.message)
    }

    return null
}

export interface ScheduledTask {
    command: string
    args: string
    mode: string
    value: string | null
}

export async function getTasks(): Promise<ScheduledTask[] | null> {
    try {
        const res = await axios.get('/api/parker/scheduler/tasks')
        if (res.status != 200) {
            throw new Error('Не удалось получить список задач')
        }

        return res.data
    } catch (ex) {
        console.error(ex)
        ToastError(ex.message)
    }

    return null
}

export async function setTasks(list: ScheduledTask[]): Promise<boolean> {
    try {
        const res = await axios.post('/api/parker/scheduler/tasks', list)
        if (res.status != 200) {
            throw new Error('Не удалось сохранить список задач')
        }

        return true
    } catch (ex) {
        console.error(ex)
        ToastError(ex.message)
        return false
    }
}

export interface Commands extends Record<string, string> {}

export async function getCommands(): Promise<Commands | null> {
    try {
        const res = await axios.get('/api/parker/scheduler/commands')
        if (res.status != 200) {
            throw new Error('Не удалось получить список доступных команд')
        }

        return res.data
    } catch (ex) {
        console.error(ex)
        ToastError(ex.message)
    }

    return null
}
