<script lang="ts">
import { defineComponent } from 'vue'
import { PrepayCard } from '@/api/cards/prepay.api'
import { ToastError, ToastSuccess } from '@/helpers/toasts'
import axios from '@/helpers/axios'

export default defineComponent({
    props: ['card'],
    emits: ['save'],
    data() {
        return {
            income: 0,
            outcome: 0,
            comment: '',
        }
    },
    computed: {
        balanceDiff() {
            return this.income - this.outcome
        },
        balanceChanges() {
            let sum = this.balanceDiff
            if (sum > 0) {
                return `+${sum}`
            } else if (sum < 0) {
                return `${sum}`
            } else {
                return ''
            }
        },
    },
    methods: {
        async save() {
            try {
                const res = await axios.post(`/api/cards/${this.card.id}/change-balance`, {
                    income: this.income,
                    outcome: this.outcome,
                    comment: this.comment,
                })
                if (res.status != 200 || res.data.error) {
                    throw new Error('Ошибка при изменении баланса')
                }

                this.income = 0
                this.outcome = 0
                this.comment = ''

                ToastSuccess('Баланс карты успешно изменен')
                this.$emit('save')
            } catch (ex) {
                ToastError(ex)
            }
        },
    },
    mounted() {},
})
</script>

<template>
    <div>
        <p>
            Текущий баланс: <b>{{ card.money }}</b> р.
        </p>

        <p>
            <span
                >Новый баланс: <b>{{ card.money + balanceDiff }}</b> р.</span
            >
            <span v-if="balanceDiff != 0">
                (<span :class="{ 'text-success': balanceDiff > 0, 'text-danger': balanceDiff < 0 }">{{ balanceChanges }}</span
                >)
            </span>
        </p>

        <div class="row">
            <div class="col">
                <div class="mb-2">
                    <label for="incomeField" class="form-label">Сумма пополнения</label>
                    <input type="number" class="form-control" id="incomeField" v-model="income" />
                </div>
            </div>

            <div class="col">
                <div class="mb-2">
                    <label for="outcomeField" class="form-label">Сумма списания</label>
                    <input type="number" class="form-control" id="outcomeField" v-model="outcome" />
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label for="commentField" class="form-label">Комментарий</label>
            <textarea class="form-control" id="commentField" rows="3" v-model="comment"></textarea>
        </div>
    </div>
</template>
