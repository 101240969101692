import {type RouteRecordRaw } from 'vue-router'
import ReportEventsView from '@/views/Reports/ReportEventsView.vue'

export const ReportEventsPage = Symbol('reports-events')

// Роуты связанные с отчетами
const routes: RouteRecordRaw[] = [
    //
    { path: '/page/gr_reports/events', name: ReportEventsPage, component: ReportEventsView },
]

export default routes
