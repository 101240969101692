import { defineStore } from 'pinia'
import api from '@/api'
import { type User, type UserParams } from '@/api/users'
import { type Arendator } from '@/api/arendators.api'
import { ToastError } from '@/helpers/toasts'
import type { LegacyPage } from '@/api/pages.api'

export type UserState = {
    user: User | null
    params: UserParams
    arendator: Arendator | null // Арендатор текущего пользователя
    pages: Record<string, Record<string, LegacyPage[]>> // Доступные пользователю страницы, в виде как на бекенде
    //permissions: Record<string, Permission> // права текущего пользователя отсортированные по названию права
}

/**
 * Хранилище данных о текущем пользователе. Запрашивается первый раз в App. Арендатор может отсутствовать.
 */
export const useCurrentUserStore = defineStore('current-user', {
    state(): UserState {
        return {
            user: null,
            params: null,
            arendator: null,
            pages: null,
        }
    },

    getters: {
        isAdmin: (state) => state.user.login == 'admin',
    },

    actions: {
        // Проверяет, что у текущего пользователя есть право
        has(name: string) {
            return IS_ADMIN// || name in this.permissions
        },

        async load() {
            try {
                const [{ user, params }, arendator] = await Promise.all([api.users.getCurrentUser(), api.arendators.getCurrentArendator()])

                this.user = user
                this.params = params
                this.arendator = arendator
            } catch (ex) {
                ToastError(ex)
                this.user = null
                this.params = null
                this.arendator = null
            }
        },
    },
})
