<script setup lang="ts">
import { onMounted, ref } from 'vue'
import api from '@/api'
import { type Card } from '@/api/cards'
import { type BalanceHistoryEntry } from '@/api/cards/prepay.api'

interface Props {
    card: Card
}

const props = defineProps<Props>()

const list = ref<BalanceHistoryEntry[]>([])
onMounted(async () => {
    list.value = await api.cards.prepay.getBalanceHistory(props.card.id)
})
</script>

<template> 
    <table class="table table-striped table-colored">
        <thead>
            <tr class="border border-1 bg-secondary-subtle border-dark-subtle align-middle">
                <th width="170px">Время</th>
                <th>Событие</th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="list.length == 0">
                <td colspan="2">Событий не зафиксировано</td>
            </tr>
            <template v-else>
                <tr v-for="(entry, id) in list" :key="id">
                    <td class="text-center">{{ entry.time }}</td>
                    <td>{{ entry.event }}</td>
                </tr>
            </template>
        </tbody>
    </table>
</template>
