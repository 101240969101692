import axios from '@/helpers/axios'
import { ToastError } from '@/helpers/toasts'

export * as common from './common.api'
export * as worktime from './worktime.api'

export interface Tariff {
    id: number
    name: string
    free_time: number
    free_time_shift: boolean
    exit_time: number
    penalty: number
    priority: number
    special_duration_time: number
    special_duration_type: string
    special_duration_data: string
    algorithm: 'BASIC' | 'PRESENCE'
    sig: number
    sig_type: number[]
    transsectoral: number
    internal: number
    disable_accepting: number
    rules: TariffRule[]
}

export interface TariffRule {
    id: number
    tariff_id: number
    priority: number
    unit: 'PER_MINUTE' | 'PER_HOUR' | 'INTERVAL' | 'DAY' | 'MONTH' | 'DATE' | 'TABLE' | 'DAY_OF_WEEK' | 'FIXED'
    start_time: string
    end_time: string
    action: 'FREE' | 'PAYMENT' | 'DENIED_ALL' | 'DENIED_GROUPS' | 'ALLOW_GROUPS'
    value: string
    enabled: boolean
}

export async function getTariffs(): Promise<Tariff[]> {
    try {
        const res = await axios.get('/api/tariffs?type=withoutCards')
        if (res.status == 200 && !res.data.error) {
            return res.data
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return null
}

export async function addTariff(tariff: Tariff): Promise<boolean> {
    try {
        const res = await axios.post('/api/tariffs', tariff)
        if (res.status == 200) {
            return true
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return false
}

export async function deleteTariff(tariffId: number): Promise<boolean> {
    try {
        await axios.delete(`/api/tariffs/${tariffId}`)
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
        return false
    }

    return false
}
