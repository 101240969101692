import axios from '@/helpers/axios'
import { toastCatch, ToastError } from '@/helpers/toasts'

export interface Permission {
    id?: number
    name: string
    desc: string
}

export interface RoutePermission {
    route: {
        method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD' | 'OPTIONS'
        path: string
    }
    permission: Permission
}

/**
 * Специфичная (пока) для API группа прав
 */
export interface PermissionsGroup {
    name: string
    path: string
    desc: string
    list: RoutePermission[]
}

export async function getUserPermissions(userId: number): Promise<Permission[]> {
    try {
        const res = await axios.get(`/api/users/${userId}/permissions`)
        if (res.status == 200 && !res.data.error) {
            return res.data
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return null
}
1
export async function getRolePermissions(roleId: number): Promise<Permission[]> {
    try {
        const res = await axios.get(`/api/roles/${roleId}/permissions`)
        if (res.status == 200 && !res.data.error) {
            return res.data
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return null
}

export type PermissionsListQuery = {
    filter?: 'api' | null
}

export async function getPermissionsList(query: PermissionsListQuery = {}) : Promise<PermissionsGroup[]> {
    return toastCatch(async () => {
        const res = await axios.get('/api/permissions', { params: query })
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return res.data
    })
}

export async function getAllApiPermissions() {
    try {
        const res = await axios.get(`/api/users/tokens/permissions?filter=api`)
        if (res.status == 200 && !res.data.error) {
            return res.data
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return null
}
