<script setup lang="ts">
import { onMounted, ref } from 'vue'
import dayjs from '@/helpers/dayjs'
import { ToastError, ToastSuccess } from '@/helpers/toasts'
import { Card, CardType } from '@/api/cards'
import api from '@/api'

interface Props {
    card: Card
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'save'): void
}>()

const validTime = ref(dayjs().format('YYYY-MM-DD'))
onMounted(() => {
    if (props.card.valid_time == '0000-00-00 00:00:00') {
        validTime.value = null
    } else {
        validTime.value = dayjs(props.card.valid_time).format('YYYY-MM-DD')
    }
})

async function save() {
    try {
        if (dayjs(validTime.value, 'YYYY-MM-DD').isAfter(dayjs('2038-01-19 00:00:00'))) {
            ToastError('На текущий момент дата не может быть больше 19.01.2038')
            return false
        }

        // Копируем карту
        const card = Object.assign({}, props.card)
        card.valid_time = validTime.value

        api.cards.saveCard(card, CardType.Prepay).then(() => {
            ToastSuccess(`Срок действия карты ${card.code} успешно изменен`)
        })

        emit('save')
    } catch (ex) {
        ToastError(ex)
    }
}

defineExpose({
    save,
})
</script>

<template>
    <div>
        <label for="validTime" class="form-label">Проезд доступен до</label>

        <input type="date" max="2038-01-01" class="form-control mb-2" id="validTime" v-model="validTime" />

        <div class="form-text">Изменение даты не влияет на состояние баланса.</div>
        <div class="form-text">Срок до указанной даты считается оплаченным.</div>
    </div>
</template>
