import axios from '@/helpers/axios'
import { toastCatch, ToastError } from '@/helpers/toasts'
import { type Permission } from './permissions.api'

export interface UserAccessToken {
    enabled: boolean
    token: string
    permissions: Permission[]
}

export async function setApiAccess(userId: number, enabled: boolean): Promise<void> {
    return toastCatch(async () => {
        await axios.post(`/api/users/${userId}/token`, {
            enabled,
        })
    })
}

export async function getTokenInfo(userId: number): Promise<UserAccessToken | null> {
    return toastCatch(async () => {
        return (await axios.get(`/api/users/${userId}/token`)).data
    })
}

export async function refreshToken(userId: number): Promise<{ token: string }> {
    return toastCatch(async () => {
        return (await axios.post(`/api/users/${userId}/token/refresh`)).data
    })
}

export async function saveTokenPermissions(userId: number, token: Permission[]) {
    return toastCatch(async () => {
        return (await axios.post(`/api/users/${userId}/token/permissions`, token)).data
    })
}
