<script setup lang="ts"></script>

<template>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <b class="card-title">Сектор 1</b>

                    <div class="d-flex flex-row align-items-center">
                        <div class="text-success">30</div>

                        <div class="progress-stacked flex-grow-1 mx-2">
                            <div class="progress" style="width: 30%">
                                <div class="progress-bar bg-success"></div>
                            </div>
                            <div class="progress" style="width: 70%">
                                <div class="progress-bar bg-danger"></div>
                            </div>
                        </div>

                        <div class="text-danger">70</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col">f</div>
    </div>
</template>
