<script setup lang="ts">
import { ref } from 'vue'
import { Card } from '@/api/cards'
import axios from '@/helpers/axios'
import LoadingGear from '@/components/LoadingGear.vue'
import { ToastSuccess } from '@/helpers/toasts'

interface Props {
    card: Card
}

const props = defineProps<Props>()
const emit = defineEmits<{
    (e: 'save'): void
}>()

const loading = ref<boolean>(false)
const file = ref<File | null>()

async function onFileChanged(e: Event) {
    loading.value = true
    const target = e.target as HTMLInputElement
    if (target && target.files) {
        file.value = target.files[0]
    }

    if (file.value) {
        const form = new FormData()
        form.append('photo', file.value)

        emit('save')
        await axios.post(`/api/cards/${props.card.id}/photo`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        ToastSuccess('Фото владельца карты успешно обновлено.')
    }
    loading.value = false
}
</script>

<template>
    <div>
        <p>Пожалуйста, убедитесь что новое фото соответствует следующим требованиям:</p>
        <ul>
            <li>Файл должен иметь расширение "png", "jpg" или "jpeg".</li>
            <li>Размер файла не должен превышать 5 мегабайт.</li>
            <li>Фото не должно быть излишне узким или широким.</li>
        </ul>

        <p>Новое фото будет отображатся в отчетах и в списке карт.</p>

        <div v-if="loading" class="text-center">
            <LoadingGear />
        </div>
        <div v-else>
            <label for="card_photo">Выберите фотографию</label>
            <input id="card_photo" type="file" name="photo" class="form-control" @change="onFileChanged" accept="image/*" capture />
        </div>
    </div>
</template>
