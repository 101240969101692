<script>
import Pagination from '@/components/Pagination.vue'
import AdvReport from '@/components/AdvReport.vue'

export default {
    name: 'ReportEventsView',
    data() {
        return {
            terminals: {},
            users: {},
            filters: {
                EVENTS: 'События',
                OPEN_GATE_BYNET: 'Открытие шлагбаума через компьютер',
                OPEN_GATE_BYEXT: 'Открытие шлагбаума по кнопке',
                OPEN_GATE_NOT_ALLOWED: 'Несанкционированное открытие шлагбаума',
                CARD_EVENTS: 'Проезды по карте',
                ERRORS: 'Ошибки и уведомления',
                USER_ACTIONS: 'Действия пользователей',
            },
        }
    },
    computed: {
        terminalsDisplay: function () {
            let names = {}
            for (let id in this.terminals) {
                let term = this.terminals[id]
                let name = TerminalTypeString(term.Type) + ' ' + term.Num
                if (term.TerminalName.length > 0) {
                    name = term.TerminalName + ' (' + name + ')'
                }

                names[term.Num] = name
            }

            names['all'] = 'Все'

            return names
        },
        usersDisplay: function () {
            let names = {}
            for (let id in this.users) {
                let usr = this.users[id]
                if (usr.role_id == '2' && usr.blocked != 'TRUE' && usr.deleted != 'TRUE') {
                    let loginUser = usr.name == usr.login ? usr.login : usr.name + ' (' + usr.login + ')'
                    let name = usr.name == null ? usr.login : loginUser
                    names[usr.id] = name
                }
            }

            names['all'] = 'Все'

            return names
        },
    },
    methods: {
        badTime(entry) {
            const d1 = dayjs(entry.Time, 'DD.MM.YYYY HH:mm:ss')
            const d2 = dayjs(entry.EventTime, 'DD.MM.YYYY HH:mm:ss')

            return d1.diff(d2, 'second') > 5
        },

        genSourceDesc(entry) {
            if (entry.TerminalName.length > 0) {
                return entry.TerminalName
            }
        },

        showPhoto(id) {
            ShowTicketsPhoto(id)
        },
        getTerminalsDisplayNames() {},
        getUsersDisplayNames() {},
    },
    async mounted() {
        const terms = await axios.get('/api/terminals')
        this.terminals = terms.data
        const oper = await axios.get('/api/users')
        this.users = oper.data
    },
    components: {
        Pagination,
        AdvReport,
    },
}
</script>
<template>
    <adv-report report-url="events" :use-terminals="terminalsDisplay" :use-users="usersDisplay" :use-search="true" :use-time="true" :filters="filters" :use-export="true" :export-to="'excel'">
        <template v-slot:default="slotProps">
            <table class="table table-striped table-colored">
                <thead>
                    <tr class="border border-1 bg-secondary-subtle border-secondary-subtle">
                        <th width="200px">Время регистрации</th>
                        <th width="180px">Время сообщения</th>
                        <th width="200px">Источник</th>
                        <th>Событие</th>
                        <!--<th width="30px"></th>-->
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="slotProps.list.length == 0">
                        <td colspan="4">Нет данных для отображения</td>
                    </tr>
                    <tr v-else v-for="(entry, id) in slotProps.list" :style="{backgroundColor: entry.Color}">
                        <td :class="{'text-danger': badTime(entry)}">{{entry.Time}}</td>
                        <td :class="{'text-danger': badTime(entry)}">{{entry.EventTime}}</td>
                        <td>{{entry.EventSource}}</td>
                        <td class="text-start">
                            <div class="d-flex align-items-center">
                                <span class="" v-html="entry.EventDescription" :class="[entry.Badge != null ? 'fs-6 text-start nowhitespaces badge text-bg-'+ entry.Badge : '']"></span>
                            </div>
                        </td>
                        <!--<td class="align-middle" style="--bs-border-width: 0;">
                            <i class="fa-regular fa-magnifying-glass"></i>
                        </td>-->
                    </tr>
                </tbody>
            </table>
        </template>
    </adv-report>
</template>

<style>
    .table-bordered> :not(caption)>*>*:last-of-type,
    .table-bordered> :not(caption)>*>*:nth-last-of-type(4) {
        --bs-border-width: 0;
    }
    .nowhitespaces {
        white-space: unset !important;
    }
</style>