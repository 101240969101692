<script lang="ts" setup>
import { computed, ref } from 'vue'
import api from '@/api'
import { useCardGroupsStore } from '@/stores/cards/groups.store'
import Modal from '@/components/Modal.vue'

const emit = defineEmits(['save'])

const dto = ref({
    code: '',
    group_id: 0,
    tabel_num: '',
    block_state: 'TRUE',
    arendator_id: 0,
    qr_code: 0,
    type: 'DISPENSERS',
})

async function addCard(card) {
    if (await api.cards.addCard(card)) {
        emit('save')
        showAddCardModal.value = false
    }
}

const showAddCardModal = ref(false)
const cardGroupsStore = useCardGroupsStore()

const groups = computed(() => cardGroupsStore.groups)
</script>

<template>
    <button class="btn btn-outline-primary" @click="showAddCardModal = true">Добавить</button>

    <Modal title="Добавление карты диспенсера" :show="showAddCardModal" @close="showAddCardModal = false" @save="addCard(dto)">
        <div class="row mb-3">
            <label for="card_code" class="col-sm-4 col-form-label">Номер карты</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="card_code" v-model="dto.code" />
            </div>
        </div>

        <div class="row mb-3">
            <label for="card_group" class="col-sm-4 col-form-label">Группа</label>
            <div class="col-sm-8">
                <select id="card_group" class="form-select" v-model="dto.group_id">
                    <option :value="0">Нет</option>
                    <option v-for="(group, id) in groups" :value="group.id" :key="id">
                        {{ group.name }}
                    </option>
                </select>
            </div>
        </div>
    </Modal>
</template>
