import axios from '@/helpers/axios'
import { ToastError } from '@/helpers/toasts'
//import type { type Permission } from './permissions.api'

//export * as permissions from './permissions.api'


export interface TariffsCommon {
    penalty: number
    arend_ticket_time: number
    accept_free_time: number
    accept_lock_time: number
    print_barcode: boolean
}

export async function getTariffsCommon(): Promise<TariffsCommon | null> {
    try {
        const res = await axios.get('/api/tariffs/common')
        if (res.status == 200) {
            return res.data
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return null
}

export async function setTariffsCommon(common: TariffsCommon): Promise<boolean> {
    try {
        const res = await axios.post('/api/tariffs/common', common)
        if (res.status == 200) {
            return true
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return false
}