<script setup lang="ts">
import { onMounted, ref, computed, watch, toRaw } from 'vue'
import api from '@/api'
import { type UserAccessToken } from '@/api/users/tokens.api'
import { useCurrentUserStore } from '@/stores/current-user.store'
import ApiTokenUsingSwitch from './ApiTokenSwitch.vue'
import { useUsersStore } from '@/stores/users.store'
import PermissionsList from './PermissionsList.vue'

const usersStore = useUsersStore()
const currentUserStore = useCurrentUserStore()

const selectedUser = ref(currentUserStore.user)

const tokenInfo = ref<UserAccessToken | null>(null)
const tokenPermissions = computed(() => tokenInfo.value.permissions)

async function loadTokenInfo() {
    tokenInfo.value = await api.users.tokens.getTokenInfo(selectedUser.value.id)
}

onMounted(async () => {
    await usersStore.load()
    selectedUser.value = toRaw(currentUserStore.user)
    await loadTokenInfo()
})
watch(selectedUser, async () => {
    await loadTokenInfo()
})

async function onSwitchToken(toggle) {
    await api.users.tokens.setApiAccess(selectedUser.value.id, tokenInfo.value.token == null)
    await loadTokenInfo()
}

async function refreshToken() {
    const res = await api.users.tokens.refreshToken(selectedUser.value.id)
    tokenInfo.value.token = res.token
}
</script>

<template>
    <template v-if="tokenInfo">
        <div v-if="currentUserStore.isAdmin">
            <select class="form-select mb-3" v-model="selectedUser">
                <option v-for="(user, id) in usersStore.list" :value="user" :key="id">{{ user.login }}</option>
            </select>
        </div>

        <ApiTokenUsingSwitch @toggle="onSwitchToken" :enabled="tokenInfo.token != null" />

        <template v-if="tokenInfo != null && tokenInfo.token != null">
            <div class="row mt-3">
                <div class="col-12 col-lg-4 offset-lg-4 text-center">
                    <label for="selected_token" class="form-text">Ваш ключ доступа:</label>

                    <div class="input-group">
                        <input
                            id="selected_token"
                            class="form-control text-center"
                            style="font-family: monospace"
                            onclick="this.select()"
                            :value="tokenInfo.token"
                            readonly
                        />
                        <button class="btn btn-outline-primary" @click="refreshToken">
                            <i class="fa-regular fa-arrows-rotate"></i>
                        </button>
                    </div>
                </div>

                <!--<div class="col-12 col-lg-8 text-end d-flex justify-content-end align-items-end mt-3 mt-lg-0">
                    <div class="btn-group me-2">
                        <button class="btn btn-outline-primary">Очистить все</button>
                        <button class="btn btn-outline-primary">Выбрать все</button>
                    </div>
                    <div><button class="btn btn-outline-primary">Открыть все</button></div>
                </div>-->
            </div>

            <hr />

            <div>
                <PermissionsList :user="selectedUser" :selected="tokenPermissions" />
            </div>
        </template>
    </template>
</template>
