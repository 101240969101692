<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { Card } from '@/api/cards'
import api from '@/api'
import dayjs from '../../helpers/dayjs'

interface Props {
    card: Card
}

const { card } = defineProps<Props>()

const list = ref([])
const loading = ref(true)

async function fetchHistory() {
    loading.value = true
    list.value = await api.cards.getCardHistory(card.id)
    loading.value = false
}

watch(() => card, fetchHistory)
onMounted(fetchHistory)
</script>

<template>
    <div v-if="!loading">
        <table class="table table-striped table-colored">
            <thead>
                <tr class="border border-1 bg-secondary-subtle border-dark-subtle align-middle">
                    <th>Время</th>
                    <th>Событие</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="list.length == 0">
                    <td colspan="2">Событий не зафиксировано</td>
                </tr>
                <tr v-else v-for="entry in list" :class="{ 'table-success': entry.event == 'IN', 'table-danger': entry.event == 'OUT' }">
                    <td class="text-center">{{ dayjs(entry.time, 'DD.MM.YYYY HH:mm:ss') }}</td>
                    <td>
                        {{ entry.event == 'IN' ? 'Заехал' : entry.event == 'OUT' ? 'Выехал' : 'Неизвестно' }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
