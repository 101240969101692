<script lang="ts" setup>
import { ref } from 'vue'
import api from '@/api'
import Modal from '@/components/Modal.vue'
import { useTariffsStore } from '@/stores/tariffs.store'
import { useCurrentUserStore } from '@/stores/current-user.store'
import { useArendatorsStore } from '@/stores/arendators.store'
import { type AddPrepayCardDto } from '@/api/cards/prepay.api'
import { ToastSuccess } from '@/helpers/toasts'

const emit = defineEmits(['save'])

const currentUserStore = useCurrentUserStore()
const arendatorsStore = useArendatorsStore()
const tariffsStore = useTariffsStore()

const dto = ref<AddPrepayCardDto>({
    code: '',
    tabel_num: '',
    owner: '',
    arendator_id: currentUserStore.user!.arendator_id,
    currentPrice: 0,
    extraTariff: 0,
    comments: ''
})

async function addCard(dto) {
    if (await api.cards.prepay.addPrepayCard(dto)) {
        ToastSuccess('Предоплатная карта успешно добавлена!')
        emit('save')
        showAddCardModal.value = false
        dto.value = {
            code: '',
            tabel_num: '',
            owner: '',
            arendator_id: currentUserStore.user!.arendator_id,
            currentPrice: 0,
            extraTariff: 0,
            comments: ''
        }
    }
}

const showAddCardModal = ref(false)
</script>

<template>
    <button class="btn btn-outline-primary" @click="showAddCardModal = true">Добавить</button>

    <Modal
        title="Добавление новой карты с предоплатой"
        :show="showAddCardModal"
        @close="showAddCardModal = false"
        @save="addCard(dto)"
    >
        <div id="idAddCardError" class="d-none alert alert-danger"></div>

        <div class="mb-3">
            <label for="card_code" class="form-label">Номер карты</label>
            <input id="card_code" type="text" class="form-control" v-model="dto.code" />
        </div>

        <div class="mb-3">
            <label for="card_grnz" class="form-label">Номер авто</label>
            <input id="card_grnz" type="text" class="form-control" v-model="dto.tabel_num" />
        </div>

        <div class="mb-3">
            <label for="card_owner" class="form-label">ФИО</label>
            <input id="card_owner" type="text" class="form-control" v-model="dto.owner" />
        </div>

        <div v-if="currentUserStore.user.arendator_id == 0" class="mb-3">
            <label for="card_arendator" class="form-label">Арендатор</label>
            <select id="card_arendator" class="form-select" v-model="dto.arendator_id">
                <option :value="0">Не назначен</option>
                <option v-for="(arend, id) in arendatorsStore.list" :key="id" :value="arend.id">
                    {{ arend.name }}
                </option>
            </select>
        </div>

        <div class="mb-3">
            <label for="card_extra_tariff" class="form-label">Доп. тариф</label>
            <select id="card_extra_tariff" class="form-select" v-model="dto.extraTariff">
                <option :value="0">Не назначен</option>
                <option v-for="(tariff, id) in tariffsStore.list" :key="id" :value="tariff.id">
                    {{ tariff.name }}
                </option>
            </select>
        </div>

        <div class="mb-3">
            <label for="card_current_price" class="form-label">Стоимость текущего месяца (руб)</label>
            <input id="card_current_price" type="number" class="form-control" v-model="dto.currentPrice" />
        </div>

        <div class="mb-3">
            <label for="card_comment" class="form-label">Комментарий</label>
            <textarea id="card_comment" class="form-control" v-model="dto.comments"></textarea>
        </div>
    </Modal>
</template>
