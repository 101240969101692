<script setup lang="ts">
import { type Card, type CardListDto, type CardListRequest, CardType } from '@/api/cards'
import api from '@/api'
import { nextTick, onMounted, ref } from 'vue'
import AddDispenserCard from './AddDispenserCard.vue'
import { useCardGroupsStore } from '@/stores/cards/groups.store'
import { ToastSuccess } from '@/helpers/toasts'

const loading = ref<boolean>(true)
const list = ref<CardListDto<Card> | null>({ list: [], count: { on_parking: 0, away: 0, unknown: 0 } })

const cardGroupsStore = useCardGroupsStore()

const params = ref<CardListRequest>({
    card_type: CardType.Dispensers,
    search: '',
    deleted: 'false',
    sort_by: 'id',
    order_by: 'DESC'
})

async function reloadList() {
    loading.value = true

    await Promise.all([
        (async () => list.value = await api.cards.getCardsList(params.value))(),
        cardGroupsStore.load(),
    ])

    loading.value = false
    nextTick(() => {
        updateVersionPoppers()
    })
}

onMounted(async () => await reloadList())

async function saveCard(card) {
    await api.cards.saveCard(card)
    ToastSuccess(`Карта ${card.code} была сохранена`)
    await reloadList()
}
async function deleteCard(card) {
    await api.cards.softDeleteCard(card)
    await reloadList()
}

async function sendAllCards() {
    await api.cards.sendAllCards()
}

async function setSort(sort_by: keyof Card) {
    if(params.value.sort_by != sort_by) {
        params.value.sort_by = sort_by
        params.value.order_by = 'DESC'
    } else {
        params.value.order_by = params.value.order_by == 'DESC' ? 'ASC' : 'DESC'
    }

    await reloadList()
}

</script>

<template>
    <div class="row">
        <div class="col-12 col-sm-8 col-md-6 order-sm-last">
            <div class="row row-cols-sm-auto g-2 align-items-center justify-content-end mb-2">
                <div class="col-auto text-start" id="dispenser_counters"></div>

                <div class="col-auto">
                    <AddDispenserCard @save="reloadList" />
                </div>
                <div class="col-auto">
                    <button class="btn btn-outline-primary" @click="sendAllCards">Разослать все карты</button>
                </div>
            </div>

            <div class="row row-cols-sm-auto g-2 align-items-center justify-content-end">
                <div class="col-12">
                    <select class="form-select" v-model="params.deleted" @change="reloadList">
                        <option value="false">Активные</option>
                        <option value="true">Удаленные</option>
                    </select>
                </div>

                <div class="col-12">
                    <div class="input-group">
                        <input type="text" class="form-control" size="10" maxlength="8" placeholder="Номер карты" v-model="params.search" />
                        <button class="btn btn-outline-primary" @click="reloadList"><i class="fa-regular fa-magnifying-glass"></i></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-sm-4 col-md-6 order-sm-1 mt-sm-0">
            <div class="row mb-2">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="input-group" style="opacity: 0">
                        <label class="input-group-text"><i class="fa-regular fa-eye"></i></label>
                        <select class="form-select" maxlength="8" disabled>
                            <option>100</option>
                            <option>500</option>
                            <option>1000</option>
                            <option>Все</option>
                        </select>
                    </div>
                </div>
            </div>

            <div v-if="list" class="row row-cols-auto g-1 mt-3">
                <span
                    >Всего карт: <b>{{ list.count.unknown + list.count.on_parking + list.count.away }}</b
                    >.</span
                >
                <span
                    >Из них <b>{{ list.count.on_parking }}</b> на парковке,</span
                >
                <span
                    ><b>{{ list.count.away }}</b> вне парковки.</span
                >
            </div>
        </div>
    </div>

    <div v-if="loading" class="text-center">
        <img src="/assets/img/gear.svg" class="loading rotate" style="vertical-align: middle" />
    </div>
    <div v-else class="table-responsive">
        <table class="table table-striped table-colored mt-3">
            <thead>
                <tr class="border border-1 bg-secondary-subtle border-secondary-subtle">
                    <th>Номер карты <i class="fa-regular fa-sort" @click="setSort('code')"></i></th>
                    <th>Состояние <i class="fa-regular fa-sort" @click="setSort('state')"></i></th>
                    <th>Группа <i class="fa-regular fa-sort" @click="setSort('group_id')"></i></th>
                    <th colspan="2" width="100px">Действия</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(card, id) in list.list" :key="id">
                    <td>
                        <a href="#" :onclick="`ShowCardMoveHistory('${card.code}')`">{{ card.code }}</a>
                    </td>

                    <td>
                        <select class="form-select" v-model="card.state">
                            <option value="UNKNOWN">Неизвестно</option>
                            <option value="ON_PARKING">На парковке</option>
                            <option value="AWAY">Вне парковки</option>
                        </select>
                    </td>

                    <td>
                        <select class="form-select" v-model="card.group_id">
                            <option :value="0">Нет</option>
                            <option v-for="(group, id) in cardGroupsStore.groups" :value="group.id" :key="id">
                                {{ group.name }}
                            </option>
                        </select>
                    </td>

                    <td
                        width="50px"
                        class="cursor-pointer"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-original-title="Сохранить карту"
                        @click="saveCard(card)"
                    >
                        <i class="fa-regular fa-check fs-5 align-middle text-success"></i>
                    </td>

                    <td
                        width="50px"
                        class="cursor-pointer"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-original-title="Удалить карту"
                        @click="deleteCard(card)"
                    >
                        <i class="fa-regular fa-trash fs-5 align-middle text-danger"></i>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
