import { createWebHistory, createRouter, type RouteRecordRaw } from 'vue-router'

import reports, { ReportEventsPage } from './reports.routes'
import cards from './cards.routes'
import parking from './parking.routes'
import settings from './gr_common/settings.routes'
import tariffs from './gr_common/tariffs.routes'
import users from './users.routes'

import TerminalsView from '@/views/TerminalsView.vue'
import NotFoundView from '@/views/NotFoundView.vue'

const routes: RouteRecordRaw[] = [
    { path: '/page/gr_common/terminals', component: TerminalsView },

    ...tariffs,
    ...settings,
    ...reports,
    ...cards,
    ...parking,
    ...users,

    // Мейн страница
    { path: '/', redirect: { name: ReportEventsPage } },

    { path: '/', redirect: { name: ReportEventsPage } },
    
    // Путь по умолчанию (404), ненайденный путь заносится в переменную
    { path: '/:pathMatch(.*)*', component: NotFoundView },
]

export function createRoutes() {
    const router = createRouter({
        history: createWebHistory(),
        routes,
    })

    return router
}
