import { ref, type Ref } from 'vue'

export type SortFunction<T> = (a: T, b: T) => 1 | 0 | -1

export type OnSortCallback<T> = (field: keyof T, direction: 'ASC' | 'DESC') => SortFunction<T>

/**
 * Композируемая сортировка для списков объектов с полем id.
 * Требует калбек, который принимает поле и направление и возвращает функцию сортировки
 * TODO: просто вызывать функцию сортировки, без калбека
 */
export function useSorting<E extends { id: number }>(onSort: OnSortCallback<E>) {
    const sortField = ref<keyof E>('id') as Ref<keyof E>
    const sortDirection = ref<'ASC' | 'DESC'>('DESC')

    const sortedList = ref<E[]>([]) as Ref<E[]> //баг вью + тса из за которого нужно приводить

    function toggleSort(field: keyof E | null = null) {
        if (field == null) {
            field = sortField.value
        }

        // Если поле тоже самое, переключаем направление
        if (sortField.value == field) {
            sortDirection.value = sortDirection.value == 'ASC' ? 'DESC' : 'ASC'
        }

        sortedList.value.sort(onSort(field, sortDirection.value))
        sortField.value = field
    }

    /**
     * Компонент кнопки сортировки для этого списка
     * @param props
     * @returns
     */
    function SortIcon(props: { field: keyof E }) {
        let icon = 'sort'

        if (props.field == sortField.value) {
            if (sortDirection.value == 'ASC') icon = 'sort-up'
            else if (sortDirection.value == 'DESC') icon = 'sort-down'
        }

        return <i onClick={() => toggleSort(props.field)} class={'cursor-pointer fa-regular fa-' + icon}></i>
    }

    return { toggleSort, sortedList, sortField, sortDirection, SortIcon }
}
