<script lang="ts">
import { ToastSuccess } from '@/helpers/toasts'
import api from '@/api'
import Modal from '@/components/Modal.vue'
import dayjs from 'dayjs'
import { defineComponent, ref } from 'vue'

export default defineComponent({
    components: { Modal },
    emits: ['save'],
    data() {
        return {
            loading: true,
            settings: {
                mode: 'PRE' as 'PRE' | 'POST',
                globalTimer: {
                    enabled: true,
                    validTime: ''
                },
                creditLimit: 0,
                scheduler: {
                    status: 'failed',
                    version: false as string | false
                }
            },
            newNextPrice: 0,
            loadingChangeMonth: false,
            paidArendator: false,
            showModal: false
        }
    },
    watch: {
        'settings.globalTimer.enabled': function (newVal, oldVal) {
            if (newVal == true && this.settings.globalTimer.validTime == null) {
                this.settings.globalTimer.validTime = dayjs().date(1).add(1, 'month').format('YYYY-MM-DD')
            }
        }
    },
    computed: {
        currentPlusMonth() {
            return dayjs(this.settings.globalTimer.validTime).locale('ru').add(1, 'month').format('D MMMM YYYY')
        }
    },
    methods: {
        async save() {
            this.loading = true

            await api.cards.prepay.setPrepaySettings(this.settings)

            ToastSuccess('Настройки сохранены!')
            this.$emit('save')
            this.showModal = false
        },
        async changePrice() {
            await api.cards.prepay.changeAllNextPrice(this.newNextPrice)
            this.$emit('save')
        },
        async changeMonth() {
            this.loadingChangeMonth = true
            if (await api.cards.prepay.changeMonth()) {
                ToastSuccess('Месячный переход завершен!')
            }
            this.loadingChangeMonth = false
            this.$emit('save')
        }
    },
    async mounted() {
        this.loading = true
        this.settings = await api.cards.prepay.getPrepaySettings()
        this.loading = false
    }
})
</script>

<template>
    <button class="btn btn-outline-primary" @click="showModal = true">Настройки</button>

    <Modal title="Настройки предоплатных карт" :show="showModal" @save="save" @close="showModal = false">
        <div v-if="loading" class="d-flex justify-content-center">
            <img class="loading rotate" src="/assets/img/gear.svg" />
        </div>
        <div v-else>
            <div class="form-check form-switch mb-3">
                <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="enable_global_timer"
                    v-model="settings.globalTimer.enabled"
                    :disabled="paidArendator"
                />
                <label class="form-check-label" for="enable_global_timer">Использовать общее время списания</label>
                <div v-if="paidArendator" class="form-text">
                    Режим активирован на уровне парковки. Вам доступно месячное продление всех карт.
                </div>
                <div v-else class="form-text">
                    При отключении функции у каждой карты будет собственное время списания.
                </div>
            </div>

            <template v-if="settings.globalTimer.enabled">
                <div class="mb-3">
                    <label for="global_timer" class="form-label">Дата следующего списания: </label>
                    <input
                        type="date"
                        class="form-control"
                        id="global_timer"
                        v-model="settings.globalTimer.validTime"
                    />
                    <div class="form-text">Дата обновляется автоматически.</div>
                </div>

                <div class="mb-3">
                    <div class="text-center">
                        <button
                            class="btn btn-sm btn-outline-primary"
                            :disabled="loadingChangeMonth"
                            @click="changeMonth"
                        >
                            Произвести списание и переход
                        </button>
                    </div>

                    <template v-if="paidArendator">
                        <div class="form-text">
                            С вашего баланса спишется сумма текущего месяца за все продлеваемые карты, а дата следующего
                            списания будет выставлена на
                            <b>{{ currentPlusMonth }}</b
                            >.
                        </div>

                        <div class="form-text">
                            Это повлияет только на неоплаченные/просроченные (за текущий месяц) карты. В случае
                            недостатка средств ни одна карта продлена не будет.
                        </div>

                        <div class="form-text">
                            Активные на момент оплаты карты обновят свой срок и спишут оплату при первой попытке
                            использования.
                        </div>
                    </template>
                    <template v-else>
                        <div class="form-text">
                            С карт спишется сумма текущего месяца, а дата следующего списания будет выставлена на
                            <b>{{ currentPlusMonth }}</b
                            >.
                        </div>
                        <div class="form-text">
                            Это повлияет только на неоплаченные (за текущий месяц) карты у которых достаточно средств на
                            счету. Остальные карты обновят свой срок действия после оплаты.
                        </div>
                    </template>
                </div>
            </template>

            <template v-if="!paidArendator">
                <hr />
                <div class="mb-3">
                    <label for="credit_limit" class="form-label">Кредитный лимит (руб.): </label>
                    <input type="number" class="form-control" id="credit_limit" v-model="settings.creditLimit" />
                    <div class="form-text">При оплате карты сначала нужно будет оплатить долг.</div>
                </div>

                <hr />
                <h5>Установить цену следующего месяца</h5>

                <div class="mb-3">
                    <label for="next_price" class="form-label">Новая цена следующего месяца (руб.): </label>
                    <div class="input-group">
                        <input type="number" class="form-control" id="next_price" v-model="newNextPrice" />
                        <button class="btn btn-outline-primary" @click="changePrice">Изменить</button>
                    </div>
                    <div class="form-text">Новая цена будет применена ко всем картам.</div>
                </div>
            </template>
        </div>
    </Modal>
</template>
