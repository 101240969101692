import {type RouteRecordRaw } from 'vue-router'
//import ServerTariffView from '@/views/Tariffs/Server/ServerTariffView.vue'

export const ServerTariffPage = Symbol('server-tariff')

const routes: RouteRecordRaw[] = [
    // TODO: остальные выводятся как обычно
    //{ path: '/page/gr_common/tariff', component: ServerTariffView },
]

export default routes
