import api from '@/api'
import { type Arendator } from '@/api/arendators.api'
import { defineStore } from 'pinia'

export type ArendatorsState = {
    list: Arendator[]
}

export const useArendatorsStore = defineStore('arendators', {
    state(): ArendatorsState {
        return {
            list: []
        }
    },
    actions: {
        async load() {
            const list = await api.arendators.getArendators()
            if(list) {
                this.list = list
            }

        }
    },
})
