import { Popover, Tooltip } from 'bootstrap'

let tooltips = []

/**
 * Пройтись по DOM и обновить все бутстраповские компоненты с toggle=tooltip и toggle=popover.
 * Это никак не связано с vue. Чтобы работало с рендером vue нужно запускать эту функцию через nextTick во время mount.
 */
export function updateTooltips() {
    if (tooltips.length > 0) tooltips.forEach((e) => e.dispose())

    tooltips = [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].map((el) => new Tooltip(el))

}

let popovers = []

export function updatePopovers() {
    if (popovers.length > 0) popovers.forEach((e) => e.dispose())

    popovers = [...document.querySelectorAll('[data-bs-toggle="popover"]')].map((el) => new Popover(el, { trigger: 'hover' }))
}
