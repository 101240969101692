import {type RouteRecordRaw } from 'vue-router'
import UserApiTokenView from '@/views/Users/ApiToken/UserApiTokenView.vue'

export const UserApiTokenPage = Symbol('users-api-tokens')

// Роуты связанные с отчетами
const routes: RouteRecordRaw[] = [
    //
    { path: '/page/gr_users/api_tokens', name: UserApiTokenPage, component: UserApiTokenView },
]

export default routes
