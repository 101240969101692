<script setup lang="ts">
import api from '@/api'
import { onMounted, ref, watch } from 'vue'
import type { Permission, PermissionsGroup as PermissionsGroupDto, RoutePermission } from '@/api/users/permissions.api'
import type { User } from '@/api/users'
import { ToastSuccess } from '@/helpers/toasts'
import PermissionsGroup from './PermissionsGroup.vue'
import { debounce, objectEquals } from '@/helpers/utils'

const props = defineProps<{ user: User; selected: Permission[] }>()

const list = ref<PermissionsGroupDto[]>([])

// Результат установки
const res = ref<Record<string, RoutePermission[] & { enabled: boolean }[]>>({})

onMounted(toggleSelected)
watch(() => props.selected, toggleSelected)

async function toggleSelected() {
    const permissions = await api.users.permissions.getPermissionsList({ filter: 'api' })

    // Подготавливаем структуру данных из двух других
    // Для получения списка для рендера с сохранением состояния переключателей
    for (const prop in permissions) {
        let group = permissions[prop]

        // Глубоко копируем
        let newgroup = JSON.parse(JSON.stringify(group))

        // Проставляем флаги для добавленых прав
        /*newgroup.list = newgroup.list.map((el) => {
        el.enabled = props.selected.find((e) => e.id === el.permission.id) != null
        return el
    })*/

        res.value[prop] = newgroup.list.map((el) => {
            el.enabled = props.selected.find((e) => e.name === el.permission.name) != null
            return el
        })
    }

    list.value = permissions
}

const debounceSave = debounce(save)
async function save() {
    let permissions = []
    for (const prop in res.value) {
        for (let p of res.value[prop]) {
            if (p.enabled) {
                permissions.push(p.permission.name)
            }
        }
    }

    await api.users.tokens.saveTokenPermissions(props.user.id, permissions)
    ToastSuccess('Изменения успешно сохранены')
}
</script>

<template>
    <div class="">
        <div class="row mb-3">
            <div class="col-9 text-start">
                <h5 class="mt-2">Права доступа</h5>
            </div>
            <div class="col-3 text-end">
                <button class="btn btn-outline-primary" @click="save">Сохранить</button>
            </div>
        </div>

        <PermissionsGroup class="mb-3" v-for="(group, prop) in list" :key="prop" :all="group" v-model="res[prop]" @toggle="debounceSave" />
    </div>
</template>
