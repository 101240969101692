import { toastCatch } from '@/helpers/toasts'
import axios from '@/helpers/axios'
import { type Card, type CardListDto } from '.'

export type PrepayCard = Card & {
    currentPrice: number
    nextPrice: number
    extraTariff: number

    // Добавляется на фронте
    validTimeColor: string
    displayValidTime: string
}

/**
 * Список для CardList
 */
export type PrepayCardListDto = CardListDto<PrepayCard> & {
    count: {
        payed: number
        not_payed: number
    }
}

export interface PrepayPagePermissions {
    create: boolean
    delete: boolean
    settings: boolean
    travel_history: boolean
    balance_history: boolean
    photo: boolean
    edit: {
        grnz: boolean
        state: boolean
        owner: boolean
        balance: boolean
        current_price: boolean
        next_price: boolean
        arendator: boolean
        valid_time: boolean
        extra_tariff: boolean
        comment: boolean
        photo: boolean
    }
}

export interface PrepaySettings {
    mode: 'PRE' | 'POST'
    globalTimer: {
        enabled: boolean
        validTime: string
    }
    creditLimit: number
    scheduler: {
        status: string
        version: false | string
    }
}

export function getPrepaySettings(): Promise<PrepaySettings> {
    return toastCatch(async () => {
        const res = await axios.get('/api/cards/prepay/settings')
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return res.data
    })
}

export function setPrepaySettings(settings: PrepaySettings): Promise<boolean> {
    return toastCatch(async () => {
        const res = await axios.post('/api/cards/prepay/settings', settings)
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return true
    })
}

/**
 * Запустить месячный переход
 */
export function changeMonth() {
    return toastCatch(async () => {
        const res = await axios.post('/api/cards/prepay/month-change')
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return true
    })
}

export function getPrepayPagePermissions() {
    return toastCatch(async () => {
        const res = await axios.get('/api/cards/prepay/page')
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return res.data
    })
}

export interface BalanceHistoryEntry {
    time: string
    event: string
}

export function getBalanceHistory(cardId: number): Promise<BalanceHistoryEntry[]> {
    return toastCatch(async () => {
        const res = await axios.get(`/api/cards/prepay/${cardId}/history`)
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return res.data
    })
}

export interface AddPrepayCardDto {
    code: string
    tabel_num: string
    owner: string
    currentPrice: number
    extraTariff: number
    comments: string
    arendator_id: number
}

export function addPrepayCard(dto: AddPrepayCardDto): Promise<boolean> {
    return toastCatch(async () => {
        const res = await axios.post(`/api/cards/prepay`, dto)
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return true
    })
}

/**
 * Сменить цену следующего месяца для всех карт
 * @param nextPrice
 * @returns
 */
export async function changeAllNextPrice(nextPrice: number) {
    return toastCatch(async () => {
        const res = await axios.post(`/api/cards/prepay`, { nextPrice })
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return true
    })
}
