import { toastCatch } from '@/helpers/toasts'
import axios from '@/helpers/axios'

export interface Arendator {
    id: number
    name: string
    inn: string
    kpp: string
    ogrn: string
    address: string
    phone: string
    email: string
    contact_name: string
    director: string
    dir_title: string
    bik: string
    ks: string
    rs: string
    end_time: string

    balance: number
    creadit_limit: number
    contract_num: string
    paid_qr_codes: boolean
    car_count: number
    passenger_car: string
    truck: number
    paid_prepay_cards: number
}

export function getArendators(): Promise<Arendator[]> {
    return toastCatch(async () => {
        const res = await axios.get('/api/arendators')
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return res.data
    })
}

export function getCurrentArendator(): Promise<Arendator> {
    return toastCatch(async () => {
        const res = await axios.get('/api/arendator')
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        if (res.data.error) {
            return null
        }

        return res.data
    })
}
