<script setup>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useCurrentUserStore } from '@/stores/current-user.store'

const route = useRoute()

//todo: loading poka permissions ne loaded
const loaded = ref(false)
onMounted(async () => {
    loaded.value = false

    const currentUserStore = useCurrentUserStore()
    await currentUserStore.load()

    loaded.value = true
})
</script>
<template>
    <RouterView v-if="loaded" />
</template>
