<template>
    <div class="row">
        <div class="col-6 offset-3">
            <div class="alert alert-danger text-start">
                <h5>Страница не найдена</h5>
                Попробуйте <a href="/">вернутся на главную</a> и попробовать снова.
            </div>
        </div>
    </div>
</template>
