import api from '@/api'
import { type CardGroupDto } from '@/api/groups.api'
import { defineStore } from 'pinia'

export type CardGroupsState = {
    groups: CardGroupDto[]
}

export const useCardGroupsStore = defineStore('cards/groups', {
    state(): CardGroupsState {
        return {
            groups: [],
        }
    },
    actions: {
        async load() {
            this.groups = await api.groups.getGroupsList()
        },
    },
})
