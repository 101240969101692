import axios from '@/helpers/axios'
import { toastCatch, ToastError } from '@/helpers/toasts'
import type { Permission } from './permissions.api'

export * as permissions from './permissions.api'
export * as tokens from './tokens.api'

export interface User {
    id: number
    arendator_id: number
    role_id: number
    login: string
    name: string
    blocked: 'FALSE' | 'TRUE'
    deleted: 'FALSE' | 'TRUE'
    phone: string | null
    user_quick_login: boolean
    permissions: Permission[]
}

export interface UserParams {
    UserLogin: string
    /**  Defaults to: 100. */
    RecordNum: number
    /**  Defaults to: rec_all. */
    RecordType: 'rec_no' | 'rec_sel' | 'rec_withoutsel' | 'rec_all'
    RecordList?: string | null
    TerminalList?: string | null
    GateList?: string | null
    SectorList?: string | null
    /**  Defaults to: ON. */
    Sound: 'ON' | 'OFF'
    /**  Defaults to: ON. */
    FreePlaces: 'ON' | 'OFF'
    /**  Defaults to: OFF. */
    ShowGuestCards: 'ON' | 'OFF'
    /**  Defaults to: OFF. */
    UploadPicture: 'ON' | 'OFF'
    /**  Defaults to: 1. */
    WebAcceptCount: number
    /**  Defaults to: 0. */
    WebAcceptTime: number
    /**  Defaults to: 0. */
    ShortTerminalsList: number
    /** Фиксировать ГРНЗ въезжающего Defaults to: false. */
    FixGRNZBeforeEnter: 'true' | 'false'
    SipPhoneNumber?: string | null
    SipPassword?: string | null
    /**  Defaults to: 0. */
    ApiTariff: number
    /**  Defaults to: 0. */
    ApiTariffPenalty: number
    /**  Defaults to: 0. */
    PayAccept: number
    /**  Defaults to: light. */
    Theme: 'light' | 'dark'
    /**  Defaults to: 0. */
    DeletedTerminalsList: number
}

export async function list() {
    try {
        const res = await axios.get('/api/users')
        if (res.status == 200) {
            return res.data
        }

        ToastError(res.data.error ?? 'Ошибка получения списка пользователей')
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return null
}

export async function getCurrentUser(): Promise<{ user: User; params: UserParams }> {
    return toastCatch(async () => {
        const res = await axios.get(`/api/user`)
        if (res.status != 200) {
            throw new Error(res.data.error ?? res.status)
        }

        return res.data
    })
}