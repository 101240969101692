<template>
    <div class="adv-report">
        <div class="row mb-3 mt-5">
            <div class="col-12 col-lg-2 col-md-4 order-last order-md-first mt-3 mt-md-0 text-left">
                <p>
                    Отображать:
                    <select v-model="size" class="form-select form-select-inline">
                        <option :value="50">50</option>
                        <option :value="100">100</option>
                        <option :value="500">500</option>
                        <option :value="1000">1000</option>
                        <option :value="0">Все</option>
                    </select>
                </p>
                <p class="mt-4">
                    Всего записей: <b>{{ history.length }}</b>
                </p>
            </div>

            <div class="col-12 col-lg-5 col-md-8 text-start">
                <div class="row mb-2" v-if="filters">
                    <label for="report_filter" class="col-md-3 col-sm-2 col-form-label">Фильтр</label>
                    <div class="col-md-9 col-sm-10">
                        <select v-model="filter" class="form-select">
                            <option v-for="(value, key) in filters" :value="key">{{ value }}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-2" v-if="useTerminals">
                    <label for="report_terminal" class="col-md-3 col-sm-2 col-form-label">Стойка</label>
                    <div class="col-md-9 col-sm-10">
                        <select v-model="filterTerminal" class="form-select">
                            <option v-for="(value, key) in useTerminals" :value="key">{{ value }}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-2" v-if="useUsers">
                    <label for="report_users" class="col-md-3 col-sm-2 col-form-label">Оператор</label>
                    <div class="col-md-9 col-sm-10">
                        <select v-model="filterUsers" class="form-select">
                            <option v-for="(value, key) in useUsers" :value="key">{{ value }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 col-12 mt-3 mt-md-0 text-sm-end text-start">
                <div v-if="useTime" class="mb-3">
                    <div class="row">
                        <label class="col-sm-4 offset-sm-3 col-form-label">Отображать с</label>
                        <div class="col-sm-5">
                            <input class="form-control mb-2" type="datetime-local" v-model="startTime" />
                        </div>
                    </div>

                    <div class="w-100"></div>

                    <div class="row">
                        <label class="col-sm-4 offset-sm-3 col-form-label">по</label>
                        <div class="col-sm-5">
                            <input class="form-control" type="datetime-local" v-model="endTime" />
                        </div>
                    </div>
                </div>

                <div class="row row-cols-auto justify-content-end align-items-center g-2">
                    <div class="col">
                        <input
                            v-if="useSearch === true"
                            class="form-control"
                            type="text"
                            placeholder="Поиск"
                            v-model="searchText"
                        />
                        <select
                            v-else-if="useSearch !== false"
                            v-model="filterSearch"
                            class="form-select"
                            style="margin-right: 5px"
                        >
                            <option v-for="(value, key) in useSearch" :value="key">{{ value }}</option>
                        </select>
                    </div>

                    <div class="col">
                        <button class="btn btn-outline-primary" @click="loadData" :disabled="loading">Показать</button>
                    </div>

                    <div v-if="useExport" class="col">
                        <!--todo: какая-то хрень-->
                        <button
                            v-if="exportTo == 'csv'"
                            class="btn btn-outline-primary"
                            @click="exportCsv"
                            :disabled="loadingExport"
                        >
                            Экспорт в CSV
                        </button>
                        <button
                            v-if="exportTo == 'excel'"
                            class="btn btn-outline-primary"
                            @click="exportEventsToExcelCostyle"
                            :disabled="loadingExport"
                        >
                            Экспорт в Excel
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <img v-if="loading" src="/assets/img/gear.svg" class="loading rotate" style="vertical-align: middle" />

        <template v-else>
            <Pagination
                v-if="history.length > 0"
                :total-pages="pageCount"
                :total="history.length"
                :per-page="size"
                :current-page="currentPage"
                @pagechanged="onPageChange"
            >
            </Pagination>

            <div class="table-responsive">
                <div style="min-width: 1200px">
                    <slot v-bind:list="paginatedData"></slot>
                </div>
            </div>

            <Pagination
                v-if="history.length > 0"
                :total-pages="pageCount"
                :total="history.length"
                :per-page="size"
                :current-page="currentPage"
                @pagechanged="onPageChange"
            >
            </Pagination>
        </template>
    </div>
</template>
<script>
import Pagination from './Pagination.vue'

export default {
    props: {
        reportUrl: {
            type: String,
            required: true,
        },
        filters: {
            required: false,
            default() {
                return false
            },
        },
        useSearch: {
            //type: Boolean,
            required: false,
            default: false,
        },
        useTerminals: {
            requred: false,
            default() {
                return false
            },
        },
        useUsers: {
            requred: false,
            default() {
                return false
            },
        },
        useTime: {
            type: Boolean,
            required: false,
            default: false,
        },
        useExport: {
            type: Boolean,
            required: false,
            default: true,
        },
        exportTo: {
            type: String,
            requred: false,
            default: 'csv',
        },
        search: {
            type: String,
            required: false,
            default: '',
        },
        start: {
            type: String,
            required: false,
            default: strDateTimeF(getStartDate1()),
        },
        end: {
            type: String,
            required: false,
            default: strDateTimeF(getEndDate()),
        },
        onChart: {
            required: false,
            default: null,
        },
    },
    data: () => {
        return {
            currentPage: 1,
            size: 100,
            history: [],
            startTime: strDateTimeF(getStartDate1()),
            endTime: strDateTimeF(getEndDate()),
            searchText: '',
            orderBy: false,
            orderType: 'DESC',
            filter: false,
            filterSearch: false,
            filterTerminal: false,
            filterUsers: false,
            loading: true,
            timerId: -1,
            loadingExport: false,
        }
    },

    computed: {
        pageCount() {
            if (this.size == 0) {
                return 1
            }
            let l = this.history.length
            let s = this.size
            return Math.ceil(l / s)
        },
        paginatedData() {
            if (this.size == 0) {
                return this.history
            }
            const start = (this.currentPage - 1) * this.size
            const end = start + this.size
            return this.history.slice(start, end)
        },
    },

    watch: {
        size(newVal, oldVal) {
            if (this.pageCount < this.currentPage) {
                this.currentPage = this.pageCount
            }
            if (newVal == 0) {
                this.currentPage = 1
            }
        },
    },

    methods: {
        onChartClick() {
            this.onChart(this.getParams())
        },

        onPageChange(page) {
            this.currentPage = page
        },

        getParams() {
            var asd = {}
            asd.start = this.startTime.replace('T', ' ')
            asd.end = this.endTime.replace('T', ' ')
            asd.page = this.currentPage
            if (this.searchText.length > 0) {
                asd.search.searchText = this.searchText
            } else if (this.search.length > 0) {
                asd.search.search = this.search
            }

            if (this.filterSearch) {
                asd.filterSearch = this.filterSearch
            }

            if (this.filterTerminal) {
                asd.filterTerminal = this.filterTerminal
            }

            if (this.filterUsers) {
                asd.filterUsers = this.filterUsers
            }

            if (this.filter) {
                asd.filter = this.filter
            }

            return asd
        },

        constructUri(exp = false) {
            const params = new URLSearchParams()
            params.set('start', this.startTime.replace('T', ' '))
            params.set('end', this.endTime.replace('T', ' '))
            if (this.size != 0) {
                //params.set('show', this.size)
            }
            params.set('page', this.currentPage)
            if (this.searchText.length > 0) {
                params.set('search', this.searchText)
            } else if (this.search.length > 0) {
                params.set('search', this.search)
            }

            if (this.filterSearch) {
                params.set('search', this.filterSearch)
            }

            if (this.filterTerminal) {
                params.set('terminal', this.filterTerminal)
            }

            if (this.filterUsers) {
                params.set('user', this.filterUsers)
            }

            if (this.filter) {
                params.set('filter', this.filter)
            }

            var url = this.reportUrl
            if (exp) {
                url = this.reportUrl + '/export'
            }

            return `/api/reports/${url}?` + params.toString()
        },

        async loadData() {
            this.loading = true
            clearInterval(this.timerId)
            try {
                const res = await axios.get(this.constructUri(false))
                if (res.status == 200 && Array.isArray(res.data)) {
                    this.history = res.data
                    this.$emit('onloaded', res.data)
                }
            } catch (e) {
                console.error(e)
            }

            this.loading = false
        },
        async exportCsv() {
            try {
                this.loadingExport = true
                const res = await axios({
                    url: this.constructUri(true),
                    method: 'GET',
                    responseType: 'blob',
                })
                if (res) {
                    // create file link in browser's memory
                    const href = URL.createObjectURL(res.data)

                    // create "a" HTLM element with href to file & click
                    const link = document.createElement('a')
                    link.href = href
                    var filename = res.headers['content-disposition'].match(/(?:\w*)=(.*)/)[1]
                    link.setAttribute('download', filename.replaceAll('"', '')) //or any other extension
                    document.body.appendChild(link)
                    link.click()

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link)
                    URL.revokeObjectURL(href)
                }
            } catch (e) {
                console.error(e)
            }
            this.loadingExport = false
        },

        async exportEventsToExcelCostyle() {
            this.exportCsv()
            return

            if (this.reportUrl == 'events' || this.reportUrl == 'arend_qr_report') {
                this.exportCsv()
                return
            }
        },
    },

    async mounted() {
        if (this.filters) {
            this.filter = Object.keys(this.filters)[0]
        }

        if (this.useSearch !== true && this.useSearch !== false) {
            this.filterSearch = Object.keys(this.useSearch)[0]
        }

        if (this.useTerminals !== false) {
            this.filterTerminal = Object.keys(this.useTerminals)[0]
        }

        if (this.useUsers !== false) {
            this.filterUsers = Object.keys(this.useUsers)[0]
        }

        await this.loadData()
    },
    components: {
        Pagination,
    },
}
</script>
