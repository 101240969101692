import * as cards from './cards'
import * as groups from './groups.api'
import * as users from './users'
import * as tariffs from './tariffs'
import * as terminals from './terminals.api'
import settings from './settings'
import * as arendators from './arendators.api'
import * as pages from './pages.api'


export default {
    cards,
    groups,
    users,
    terminals,
    tariffs,
    settings,
    arendators,
    pages
}
