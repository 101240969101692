<script setup lang="ts">
/**
 * Карточка-переключатель ключа апи. При переключении создает-удаляет ключ.
 */
const emit = defineEmits(['toggle'])

interface Props {
    enabled: boolean
    disabled?: boolean
}

withDefaults(defineProps<Props>(), {
    disabled: false
})
</script>

<template>
    <div class="card cursor-pointer" @click.prevent="emit('toggle', enabled)">
        <div class="card-body">
            <div class="form-check form-switch">
                <input
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="token_toggle"
                    :checked="enabled"
                    :disabled="disabled"
                />
                <label class="form-check-label" for="token_toggle">Разрешить доступ к API</label>

                <div class="form-text">
                    <b>Позволяет сторонним системам подключится</b> к программному интерфейсу сервера в качестве
                    текущего пользователя и получить от его имени доступ к указанным ресурсам.
                </div>

                <div class="form-text">Для получения документации на API обратитесь в техподдержку.</div>
            </div>
        </div>
    </div>
</template>
