<script setup lang="ts">
import { ref, onMounted } from 'vue'
import TaskListItem from './TaskListItem.vue'
import { ToastSuccess } from '@/helpers/toasts'
import api from '@/api'
import { Commands, ScheduledTask, SchedulerStatus } from '@/api/settings/scheduler.api'
import { useCurrentUserStore } from '@/stores/current-user.store'

const currentUserStore = useCurrentUserStore()
const canWrite = ref(currentUserStore.has('pages.admin_main/parker_scheduler.write'))

const loading = ref(true)
const error = ref(false)

//
// Состояние планировщика
const status = ref<SchedulerStatus>({
    status: 'failure',
    version: '0.0.0',
})

// Запрос состояния
const getstatus = async () => {
    const res = await api.settings.scheduler.getStatus()
    if (res == null) {
        error.value = true
        return
    }

    status.value = res
}

//
// Список доступных команд
const commands = ref<Commands>()

// Запрос списка команд
const getcommands = async () => {
    const res = await api.settings.scheduler.getCommands()
    if (res == null) {
        error.value = true
        return
    }

    commands.value = res
}

//
// Список запланированных команд
const tasks = ref<ScheduledTask[]>([])

// Запрос списка задач
const gettasks = async () => {
    const res = await api.settings.scheduler.getTasks()
    if (res == null) {
        error.value = true
        return
    }

    tasks.value = res
}

// Ожидаем всё это
onMounted(async () => {
    loading.value = true
    await Promise.all([getstatus(), getcommands(), gettasks()])
    loading.value = false
})

/////////////////////

/*const allowedCommands = ref({})


async function loadAll() {
    loading.value = true
    status.value = await api.getStatus()
    if (!status.value) {
        ToastError('Ошибка получения статуса планировщика')
    }

    allowedCommands.value = await api.getCommands()
    if (!allowedCommands.value) {
        ToastError('Ошибка получения списка команд')
    }

    tasks.value = (await api.getTasks()).map((el) => {
        let parts = el.command.split(' ')
        el.command = parts.shift()
        el.args = parts.join(' ')
        return el
    })
    if (!tasks.value) {
        ToastError('Ошибка получения списка задач')
    }
    loading.value = false
}

async function saveTasks() {
    loading.value = true
    const res = await api.setTasks(
        tasks.value.map((el) => {
            el.command = (el.command + ' ' + el.args).trim()
            delete el.args
            return el
        }),
    )
    if (!res || res.status == 'error') {
        ToastError(res.message || 'Ошибка сохранения задач!')
    } else {
        ToastSuccess('Список задач сохранен')
    }

    await loadAll()
    loading.value = false
}*/

async function saveTasks() {
    loading.value = true

    if (await api.settings.scheduler.setTasks(tasks.value)) {
        ToastSuccess('Список задач сохранен')
        await gettasks()
    }
    loading.value = false
}

function addTask() {
    tasks.value.push({
        command: '',
        args: '',
        mode: 'days',
        value: '00:00',
    })
}

function deleteTask(id) {
    tasks.value.splice(id, 1)
}
</script>

<template>
    <div class="row mb-3">
        <div class="col-12 col-lg-8 offset-lg-2">
            <div v-if="loading" class="d-flex justify-content-center">
                <LoadingGear />
            </div>
            <div v-else-if="status.status != 'running'" class="alert alert-danger">
                <h5>Планировщик не запущен</h5>
                Настройка невозможна
            </div>
            <div v-else>
                <div class="row mb-3">
                    <div class="col-12 col-sm-6"></div>

                    <div class="col-12 col-sm-6 d-flex justify-content-end">
                        <table>
                            <tr>
                                <td>Статус планировщика:</td>
                                <td>
                                    <span v-if="status.status == 'running'" class="badge bg-success">Работает</span>
                                    <span v-else class="badge bg-secondary">Не работает</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Версия планировщика:</td>
                                <td>
                                    <b>{{ status.version }}</b>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-12 col-sm-6 d-flex justify-content-between align-items-center order-last order-sm-first">
                        <h4 class="mb-sm-0">Запланированные задачи:</h4>
                    </div>

                    <div class="col-12 col-sm-6 text-end">
                        <button class="btn btn-outline-primary me-2" @click="addTask" :disabled="!canWrite">Добавить</button>
                        <button class="btn btn-outline-primary" @click="saveTasks" :disabled="!canWrite">Сохранить</button>
                    </div>
                </div>

                <h5 v-if="tasks.length == 0" class="text-center">Список задач пуст</h5>
                <template v-else class="row mb-3">
                    <ul class="list-group p-0">
                        <TaskListItem
                            v-for="(task, id) in tasks"
                            :commands="commands"
                            :item="task"
                            :key="id"
                            @delete="deleteTask(id)"
                            :disabled="!canWrite"
                        />
                    </ul>

                    <div class="mt-2 text-end">
                        <button class="btn btn-outline-primary me-2" @click="addTask" :disabled="!canWrite">Добавить</button>
                        <button class="btn btn-outline-primary" @click="saveTasks" :disabled="!canWrite">Сохранить</button>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
