import axios from '@/helpers/axios'
import { ToastError } from '@/helpers/toasts'

/**
 * Возвращает список номеров терминалов без тарифа
 * @returns 
 */
export async function getAttentions(): Promise<number[]> {
    try {
        const res = await axios.get('/api/terminals/attentions')
        if (res.status == 200 && !res.data.error) {
            return res.data
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return null
}
