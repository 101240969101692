<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { updateTooltips } from '@/helpers/bootstrap'
import api from '@/api'
import { type Card, type CardListRequest, CardType } from '@/api/cards'
import type { PrepayCard, PrepayCardListDto, PrepayPagePermissions, PrepaySettings } from '@/api/cards/prepay.api'
import dayjs from '@/helpers/dayjs'
import { ToastSuccess } from '@/helpers/toasts'
import { useArendatorsStore } from '@/stores/arendators.store'
import { useCurrentUserStore } from '@/stores/current-user.store'
import { useTariffsStore } from '@/stores/tariffs.store'
import Modal from '@/components/Modal.vue'
import LoadingGear from '@/components/LoadingGear.vue'
import Pagination from '@/components/Pagination.vue'
import CardStateButton from '../CardStateButton.vue'
import AddPrepayCardModal from './AddPrepayCardModal.vue'
import PrepaySettingsModal from './PrepaySettingsModal.vue'
import CardMoveHistory from '../CardMoveHistory.vue'
import CardBalanceHistory from './CardBalanceHistory.vue'
import ChangeCardBalance from './ChangeCardBalance.vue'
import ChangeValidTime from './ChangeValidTime.vue'
import CardOwnerPhoto from '../CardOwnerPhoto.vue'
import CardUploadOwnerPhoto from '../CardUploadOwnerPhoto.vue'
import { useSorting } from '@/composables/sorting.tsx'

const currentUserStore = useCurrentUserStore()
const tariffsStore = useTariffsStore()
const arendatorsStore = useArendatorsStore()

// Права доступа и связанное, получаем через апи
const can = ref<PrepayPagePermissions>({
    create: false,
    delete: false,
    settings: false,
    travel_history: false,
    balance_history: false,
    photo: false,
    edit: {
        grnz: false,
        state: false,
        owner: false,
        balance: false,
        current_price: false,
        next_price: false,
        arendator: false,
        valid_time: false,
        extra_tariff: false,
        comment: false,
        photo: false
    }
})

const canSave = computed(() => Object.values(can.value.edit).includes(true))
const canSettings = computed(() => {
    return (
        can.value.balance_history ||
        can.value.edit.balance ||
        can.value.edit.valid_time ||
        can.value.photo ||
        can.value.edit.photo ||
        can.value.delete
    )
})
const actionsColspan = computed(() => {
    let count = 0
    if (canSave.value) {
        count++
    }
    if (canSettings.value) {
        count++
    }

    return count
})

// Настройки предоплатных карт (глобальные/арендаторские)
const settings = ref<PrepaySettings>({
    mode: 'PRE',
    globalTimer: {
        enabled: false,
        validTime: ''
    },
    creditLimit: 0,
    scheduler: {
        status: '',
        version: false
    }
})

async function loadPrepaySettings() {
    settings.value = await api.cards.prepay.getPrepaySettings()
}

const showMonthChangeWarning = computed(() => {
    let diff = dayjs().diff(settings.value.globalTimer.validTime, 'day', true)
    return diff <= 0 && diff >= -3
})
const hasScheduler = computed(() => settings.value.scheduler.status !== 'failed')
const validDate = computed(() => {
    return dayjs(settings.value.globalTimer.validTime).locale('ru').format('D MMMM')
})
const timeRemains = computed(() => {
    if (!settings.value) {
        return ''
    }
    var num = Math.ceil(dayjs(settings.value.globalTimer.validTime).diff(dayjs(), 'day', true))
    if (num < 0) num = 0

    var titles = ['день', 'дня', 'дней']
    var cases = [2, 0, 1, 1, 1, 2]

    return num + ' ' + titles[num % 100 > 4 && num % 100 < 20 ? 2 : cases[Math.min(num % 10, 5)]]
})

async function doChangeMonth() {
    if (await api.cards.prepay.changeMonth()) {
        ToastSuccess('Месячный переход завершен!')
    }
}

// Запрос для фильтрации списка/пагинация
const cardsQuery = ref<CardListRequest>({
    card_type: CardType.Prepay,
    sort_by: 'id',
    order_by: 'ASC',
    search: '',
    deleted: 'false',
    page: 1,
    limit: 100
})

// Обновляем список при переключении
watch(() => cardsQuery.value.deleted, loadCards)
watch(() => cardsQuery.value.limit, loadCards)

function onPageChange(page: number) {
    cardsQuery.value.page = page
    loadCards()
}

// Результат фильтрации списка
const list = ref<PrepayCardListDto>({
    list: [],
    count: {
        payed: 0,
        not_payed: 0
    }
})

//
const loading = ref<boolean>(true)

async function loadCards() {
    loading.value = true

    const [, cardsList] = await Promise.all([
        loadPrepaySettings(),
        api.cards.getCardsList<PrepayCardListDto, PrepayCard>(cardsQuery.value)
    ])

    list.value = cardsList
    list.value.list = list.value.list.map((card) => {
        if (!card.valid_time || card.valid_time == '0000-00-00 00:00:00') {
            card.displayValidTime = 'Не оплачена'
        } else {
            if (dayjs().isAfter(dayjs(card.valid_time))) {
                card.validTimeColor = 'text-danger'
            } else {
                card.validTimeColor = 'text-success'
            }

            card.displayValidTime = dayjs(card.valid_time).format('DD.MM.YYYY')
        }

        return card
    })

    // Сортируем при прогрузке
    sortedList.value = list.value.list
    toggleSort()

    loading.value = false

    // Обновляем бутстраповские подсказки
    nextTick(updateTooltips)
}

async function restoreCard(card: Card) {
    if (await api.cards.restoreCard(card, CardType.Prepay)) {
        list.value.list.splice(list.value.list.indexOf(card as PrepayCard), 1)
    }
}

async function deleteCard(card: Card) {
    if (await api.cards.deleteCard(card, CardType.Prepay)) {
        list.value.list.splice(list.value.list.indexOf(card as PrepayCard), 1)
    }
}

async function saveCard(card: PrepayCard) {
    if (await api.cards.saveCard(card, CardType.Prepay)) {
        //
    }
}

async function softDeleteCard(card: PrepayCard) {
    if (await api.cards.softDeleteCard(card, CardType.Prepay)) {
        list.value.list.splice(list.value.list.indexOf(card as PrepayCard), 1)
    }
}

// Загружаем всякое
const preloading = ref(true)

onMounted(async () => {
    preloading.value = true

    // Загружаем все требуемые данные асинхронно
    await Promise.all([
        tariffsStore.load(),
        arendatorsStore.load(),
        (async () => {
            can.value = await api.cards.prepay.getPrepayPagePermissions()
        })(),
        loadCards()
    ])

    preloading.value = false

    // Из за наличия флажка preloading (не особо нужного на самом деле)
    // при первой прогрузке не срабатывает инит подсказок при loadCards
    nextTick(updateTooltips)
})

// Переделанная сортировка
const { toggleSort, sortedList, SortIcon } = useSorting<PrepayCard>((field, direction) => {
    let sortFunc = null
    switch (field) {
        case 'tabel_num':
        case 'owner':
            if (direction == 'ASC') {
                sortFunc = (a, b) => (a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1)
            } else {
                sortFunc = (a, b) => (a[field].toLowerCase() < b[field].toLowerCase() ? 1 : -1)
            }
            break
        case 'money':
        case 'code':
            if (direction == 'ASC') {
                sortFunc = (a, b) => (a[field] > b[field] ? 1 : -1)
            } else {
                sortFunc = (a, b) => (a[field] < b[field] ? 1 : -1)
            }
            break
        default:
            sortFunc = (a, b) => (a > b ? 1 : -1)
    }

    return sortFunc
})

///////////////////////////
// Минус этого ниже в том что есть вероятность активации двух окон одновременно

// Режим оплаты через арендатора. Брать из текущего арендатора юзера
const arendatorPaid = ref(null)

// Флаг для отображения модалки с историей проездов
const activeCardHistory = ref<Card | null>()

// Флаг для отображения модалки с историей баланса
const activeBalanceHistory = ref<Card | null>()

// Флаг для отображения модалки с историей баланса
const activeChangeBalance = ref<Card | null>()
const changeCardBalanceRef = ref(null) // затычка для вызова save из дочернего при срабатывании родительского save

// Флаг для отображения модалки со сменой баланса
const activeChangeValidTime = ref<Card | null>(null)
const changeValidTimeRef = ref(null)

// Флаг для отображения фото владельца карты
const activeCardOwnerPhoto = ref<Card | null>()

// Флаг для отображения загрузки фото владельца карты
const activeUpdateCardOwnerPhoto = ref<Card | null>()
const updateCardOwnerPhotoRef = ref(null)
</script>

<template>
    <Modal
        :title="`История проездов по карте ${activeCardHistory?.code}`"
        :show="!!activeCardHistory"
        @close="activeCardHistory = null"
    >
        <CardMoveHistory :card="activeCardHistory" />
    </Modal>

    <Modal
        :title="`История счета карты ${activeBalanceHistory?.code}`"
        size="lg"
        :show="!!activeBalanceHistory"
        @close="activeBalanceHistory = null"
    >
        <CardBalanceHistory :card="activeBalanceHistory" />
    </Modal>

    <Modal
        :title="`Фото владельца карты ${activeCardOwnerPhoto?.code}`"
        :show="!!activeCardOwnerPhoto"
        @close="activeCardOwnerPhoto = null"
    >
        <CardOwnerPhoto :card="activeCardOwnerPhoto" />
    </Modal>

    <Modal
        :title="`Загрузка фото владельца карты ${activeUpdateCardOwnerPhoto?.code}`"
        :show="!!activeUpdateCardOwnerPhoto"
        @save="updateCardOwnerPhotoRef.save()"
        @close="activeUpdateCardOwnerPhoto = null"
    >
        <CardUploadOwnerPhoto
            :card="activeUpdateCardOwnerPhoto"
            ref="updateCardOwnerPhotoRef"
            @save="
                () => {
                    loadCards()
                    activeUpdateCardOwnerPhoto = null
                }
            "
        />
        <template v-slot:footer>
            <button class="btn btn-secondary" @click="activeUpdateCardOwnerPhoto = null">Закрыть</button>
        </template>
    </Modal>

    <Modal
        :title="`Изменение баланса карты ${activeChangeBalance?.code}`"
        :show="!!activeChangeBalance"
        @close="activeChangeBalance = null"
        @save="changeCardBalanceRef.save()"
    >
        <ChangeCardBalance
            :card="activeChangeBalance"
            ref="changeCardBalanceRef"
            @save="
                () => {
                    loadCards()
                    activeChangeBalance = null
                }
            "
        />
    </Modal>

    <Modal
        :title="`Смена срока действия карты ${activeChangeValidTime?.code}`"
        :show="!!activeChangeValidTime"
        @close="activeChangeValidTime = null"
        @save="changeValidTimeRef.save()"
    >
        <ChangeValidTime
            :card="activeChangeValidTime"
            ref="changeValidTimeRef"
            @save="
                () => {
                    loadCards()
                    activeChangeValidTime = null
                }
            "
        />
    </Modal>

    <div
        v-if="!preloading && settings && settings.globalTimer.enabled && hasScheduler && showMonthChangeWarning"
        class="alert alert-warning"
    >
        <i class="bi bi-exclamation-triangle-fill"></i>
        <template v-if="hasScheduler">
            <b>{{ validDate }}</b> в <b>00:00</b> произойдет автоматическое списание средств и месячный переход.
        </template>
        <template else>
            <b>{{ validDate }}</b> в <b>00:00</b> срок действия карт закончится.
            <button class="btn btn-sm btn-outline-secondary ms-2" @click="doChangeMonth">
                Произвести списание и переход
            </button>
        </template>
    </div>

    <div v-if="!preloading" class="row">
        <div class="col-2 text-left">
            Отображать:
            <select class="form-select form-select-inline" v-model="cardsQuery.limit" id="cards_show_sel">
                <option :value="100">100</option>
                <option :value="500">500</option>
                <option :value="1000">1000</option>
                <option :value="list.count.payed + list.count.not_payed">Все</option>
            </select>
        </div>

        <div class="col-6 d-flex align-items-center justify-content-end text-right g-3 offset-4">
            <div class="row row-cols-auto g-2 justify-content-end">
                <div class="col">
                    <select class="form-select me-2" v-model="cardsQuery.deleted" id="cards_stat_sel">
                        <option :value="'false'">Активные</option>
                        <option :value="'true'">Удаленные</option>
                    </select>
                </div>

                <div v-if="can.create" class="col">
                    <AddPrepayCardModal @save="loadCards" />
                </div>

                <div v-if="can.settings" class="col">
                    <PrepaySettingsModal @save="loadCards" />
                </div>
            </div>
        </div>
    </div>

    <div v-if="!preloading" class="row mt-3">
        <div class="col-12 col-md-4 col-lg-6 order-last order-md-4">
            <b
                >Всего карт: {{ list.count.payed + list.count.not_payed }}. Из них {{ list.count.not_payed }} не
                оплачено.</b
            >

            <p v-if="timeRemains">До следующего списания {{ timeRemains }}.</p>
            <p v-else>Пора произвести списание.</p>
        </div>

        <div class="col-12 col-md-8 col-lg-6 order-md-last">
            <div class="row row-cols-auto justify-content-end align-items-center g-2">
                <div class="col d-none d-sm-block">
                    <label for="search_field" class="form-text d-inline-block text-center"
                        ><b
                            >Номер карты,<br />
                            ФИО, номер авто <br />или комментарий</b
                        ></label
                    >
                </div>

                <div class="col">
                    <input
                        v-model="cardsQuery.search"
                        id="search_field"
                        class="form-control"
                        size="10"
                        placeholder="Поиск"
                    />
                </div>

                <div class="col">
                    <button class="btn btn-outline-primary" @click="loadCards" id="cards_show_btn">Показать</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="preloading || loading" class="text-center">
        <LoadingGear />
    </div>
    <template v-else>
        <Pagination
            v-if="list.count.payed + list.count.not_payed > cardsQuery.limit"
            :total-pages="Math.ceil((list.count.payed + list.count.not_payed) / cardsQuery.limit) - 1"
            :total="cardsQuery.limit"
            :per-page="cardsQuery.limit"
            :current-page="cardsQuery.page"
            @pagechanged="onPageChange"
        />

        <div class="row">
            <table class="table table-striped table-colored" style="min-width: 1200px">
                <thead>
                    <tr class="border border-1 bg-secondary-subtle border-dark-subtle align-middle">
                        <th width="102px" rowspan="2">ГРНЗ <SortIcon field="tabel_num" /></th>

                        <th width="92px" rowspan="2">Карта <SortIcon field="code" /></th>

                        <th width="64px" rowspan="2">
                            <i class="fa-regular fa-square-parking align-middle fs-2"></i>
                            <SortIcon field="state" />
                        </th>

                        <th width="192px" rowspan="2">ФИО <SortIcon field="owner" /></th>

                        <th v-if="can.edit.arendator" width="92px" rowspan="2">Арендатор</th>

                        <th v-if="!arendatorPaid" rowspan="2" width="92px">Баланс <SortIcon field="money" /></th>

                        <th width="130px" colspan="2">Цена</th>

                        <th :width="arendatorPaid ? '184px' : '92px'" rowspan="2">Срок <br />действия</th>

                        <th width="182px" rowspan="2">Доп. тариф</th>

                        <th :width="arendatorPaid ? '184px' : '92px'" rowspan="2">Комментарий</th>

                        <th width="70px" v-if="actionsColspan > 0" :colspan="actionsColspan" rowspan="2">Действие</th>
                    </tr>

                    <tr>
                        <th class="text-capitalize" width="100px">Текущий</th>
                        <th class="text-capitalize" width="100px">Следующ.</th>
                    </tr>
                </thead>

                <tbody>
                    <template v-if="list.list.length > 0">
                        <tr v-for="(card, id) in sortedList" :key="id">
                            <td>
                                <input
                                    class="form-control form-control-expanding"
                                    type="text"
                                    v-model="card.tabel_num"
                                    :disabled="!can.edit.grnz"
                                />
                            </td>

                            <td class="form-text">
                                <small
                                    v-if="can.travel_history"
                                    @click="activeCardHistory = card"
                                    class="text-decoration-underline cursor-pointer"
                                    >{{ card.code }}</small
                                >
                                <small v-else>{{ card.code }}</small>
                            </td>

                            <td :class="{ 'cursor-pointer': can.edit.state }">
                                <CardStateButton v-model="card.state" :disabled="!can.edit.state" />
                            </td>

                            <td>
                                <input
                                    type="text"
                                    class="form-control form-control-expanding"
                                    v-model="card.owner"
                                    :disabled="!can.edit.owner"
                                />
                            </td>

                            <td v-if="can.edit.arendator">
                                <select v-model="card.arendator_id" class="form-select">
                                    <option :value="null">Нет</option>
                                    <option
                                        v-for="(arendator, id) in arendatorsStore.list"
                                        :key="id"
                                        :value="arendator.id"
                                    >
                                        {{ arendator.name }}
                                    </option>
                                </select>
                            </td>

                            <td v-if="!arendatorPaid">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span class="ms-2" :class="{ 'text-danger fw-bold': card.money < 0 }"
                                        >{{ card.money }}р.</span
                                    >
                                    <div>
                                        <i
                                            v-if="can.balance_history"
                                            class="fa-regular fa-receipt cursor-pointer align-middle fs-5 d-block mb-1"
                                            @click="activeBalanceHistory = card"
                                            title="Просмотреть историю счета"
                                            data-bs-toggle="tooltip"
                                        ></i>

                                        <i
                                            v-if="can.edit.balance"
                                            class="fa-regular fa-coins cursor-pointer align-middle fs-5 d-block"
                                            @click="activeChangeBalance = card"
                                            title="Изменить баланс"
                                            data-bs-toggle="tooltip"
                                        ></i>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <template v-if="card.validTimeColor == 'bg-success'">
                                    {{ card.currentPrice }}
                                </template>
                                <template v-else>
                                    <input
                                        type="number"
                                        v-model="card.currentPrice"
                                        class="form-control"
                                        :disabled="!can.edit.current_price"
                                    />
                                </template>
                            </td>

                            <td>
                                <input
                                    class="form-control"
                                    type="number"
                                    v-model="card.nextPrice"
                                    :disabled="!can.edit.next_price"
                                />
                            </td>

                            <td :class="card.validTimeColor">
                                {{ card.displayValidTime }}
                            </td>

                            <td>
                                <select
                                    v-if="
                                        tariffsStore.list.find((tariff) => tariff.id == card.extraTariff) != null &&
                                        !currentUserStore.isAdmin
                                    "
                                    :disabled="!can.edit.extra_tariff"
                                    class="form-select"
                                >
                                    <option>Нет доступа</option>
                                </select>

                                <select
                                    v-else
                                    class="form-select"
                                    v-model="card.extraTariff"
                                    :disabled="!can.edit.extra_tariff"
                                >
                                    <option :value="0">Не выбран</option>
                                    <option v-for="(tariff, id) in tariffsStore.list" :key="id" :value="tariff.id">
                                        {{ tariff.name }}
                                    </option>
                                </select>
                            </td>

                            <td>
                                <input
                                    type="text"
                                    v-model="card.comments"
                                    class="form-control"
                                    :disabled="!can.edit.comment"
                                />
                            </td>

                            <template v-if="cardsQuery.deleted == 'true'">
                                <td
                                    v-if="can.delete"
                                    class="csRequestsListCell cursor-pointer"
                                    @click="restoreCard(card)"
                                    data-bs-toggle="tooltip"
                                    title="Восстановить"
                                >
                                    <i class="fa-regular fa-trash-undo align-middle text-success fs-5"></i>
                                </td>
                                <td
                                    v-if="can.delete"
                                    class="csRequestsListCell cursor-pointer"
                                    @click="deleteCard(card)"
                                    data-bs-toggle="tooltip"
                                    title="Удалить окончательно"
                                >
                                    <i class="fa-regular fa-trash align-middle text-danger fs-5"></i>
                                </td>
                            </template>
                            <template v-else>
                                <td
                                    v-if="canSave"
                                    class="csRequestsListCell cursor-pointer"
                                    @click="saveCard(card)"
                                    data-bs-toggle="tooltip"
                                    title="Сохранить карту"
                                >
                                    <i class="fa-regular fa-check fs-5 align-middle text-success"></i>
                                </td>

                                <td v-if="canSettings" class="cursor-pointer dropdown position-static">
                                    <i
                                        class="fa-regular fa-ellipsis fs-5 align-middle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        type="button"
                                    ></i>
                                    <ul class="dropdown-menu">
                                        <li v-if="can.balance_history">
                                            <a class="dropdown-item align-middle" @click="activeBalanceHistory = card">
                                                <i class="fa-regular fa-square-poll-horizontal"></i> Просмотреть историю
                                                счета
                                            </a>
                                        </li>

                                        <li v-if="can.edit.balance">
                                            <a class="dropdown-item align-middle" @click="activeChangeBalance = card">
                                                <i class="fa-regular fa-coins"></i> Изменить баланс
                                            </a>
                                        </li>

                                        <li v-if="can.edit.valid_time">
                                            <a class="dropdown-item align-middle" @click="activeChangeValidTime = card">
                                                <i class="fa-regular fa-stopwatch"></i> Изменить срок действия
                                            </a>
                                        </li>

                                        <li v-if="can.photo">
                                            <a class="dropdown-item align-middle" @click="activeCardOwnerPhoto = card">
                                                <i class="fa-regular fa-image"></i> Просмотреть фото
                                            </a>
                                        </li>

                                        <li v-if="can.edit.photo">
                                            <a
                                                class="dropdown-item align-middle"
                                                @click="activeUpdateCardOwnerPhoto = card"
                                            >
                                                <i class="fa-regular fa-camera"></i> Изменить фото
                                            </a>
                                        </li>

                                        <li v-if="can.delete">
                                            <a
                                                class="dropdown-item align-middle text-danger"
                                                href="#"
                                                @click="softDeleteCard(card)"
                                            >
                                                <i class="fa-regular fa-trash"></i> Удалить
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </template>
                        </tr>
                    </template>

                    <tr v-else>
                        <td :colspan="actionsColspan + (can.edit.arendator ? 11 : 10)">Нет данных для отображения</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <Pagination
            v-if="list.count.payed + list.count.not_payed > cardsQuery.limit"
            :total-pages="Math.ceil((list.count.payed + list.count.not_payed) / cardsQuery.limit) - 1"
            :total="cardsQuery.limit"
            :per-page="cardsQuery.limit"
            :current-page="cardsQuery.page"
            @pagechanged="onPageChange"
        />
    </template>
</template>
