<script setup lang="ts">
import type { PermissionsGroup, RoutePermission } from '@/api/users/permissions.api'

/**
 * Одно вхождение (Один спойлер/аккордион)
 */

const { all } = defineProps<{ all: PermissionsGroup }>()
const model = defineModel<RoutePermission[] & { enabled: boolean }[]>()
/*const emit = defineEmits<{
    (e: 'toggle', perm: RoutePermission & { enabled: boolean }): void
}>()*/
const emit = defineEmits(['toggle'])

const accordId = 'accord' + Math.random().toString(16).slice(2)
</script>

<template>
    <div class="accordion">
        <div class="accordion-item">
            <h2 class="accordion-header">
                <div class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#' + accordId">
                    <div class="accordion-header user-select-none">
                        <b class="me-2">{{ all.path }}</b> ({{ all.desc }})
                    </div>
                </div>
            </h2>

            <div :id="accordId" class="accordion-collapse collapse show">
                <div class="accordion-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th width="30%">Описание</th>
                                <th width="50px">Метод</th>
                                <th>Маршрут</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(perm, id) in model" :key="id">
                                <td>
                                    <div class="form-check form-switch">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            :id="perm.permission.name"
                                            v-model="perm.enabled"
                                            v-on:change="emit('toggle', perm)"
                                        />
                                        <label class="form-check-label" :for="perm.permission.name">{{
                                            perm.permission.desc
                                        }}</label>
                                    </div>
                                </td>
                                <td>
                                    <label class="form-check-label" :for="perm.permission.name"
                                        ><b>{{ perm.route.method }}</b></label
                                    >
                                </td>
                                <td>
                                    <label class="form-check-label" :for="perm.permission.name">{{
                                        perm.route.path
                                    }}</label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
