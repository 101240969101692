<script lang="ts" setup>
import { computed, getCurrentInstance, useSlots } from 'vue'

interface Props {
    title?: string
    size?: string
    show: boolean
}

const props = withDefaults(defineProps<Props>(), {
    title: 'Заголовок',
    size: '',
    show: false,
})

// Отображаем кнопки только если установлено соотетствующее событие
const emit = defineEmits(['close', 'save'])
const hasSave = computed(() => !!getCurrentInstance()?.vnode.props?.['onSave'])
const hasClose = computed(() => !!getCurrentInstance()?.vnode.props?.['onClose'])

// Если есть слот под футер, кнопки не рисуем - рисуем слот
const slots = useSlots()
const hasFooter = computed(() => !!slots['footer'])
</script>

<template>
    <Teleport to="body">
        <Transition name="modal-backdrop">
            <div v-if="props.show" class="modal-backdrop"></div>
        </Transition>
        <Transition name="modal">
            <div v-if="props.show" class="modal d-block" @click.self="emit('close')">
                <div class="modal-dialog modal-dialog-scrollable" :class="size != '' ? 'modal-' + size : ''">
                    <div class="modal-content">
                        <div class="modal-header ap-modal-header">
                            <h5 class="modal-title">{{ title }}</h5>
                            <button type="button" class="btn-close" @click="emit('close')"></button>
                        </div>
                        <div class="modal-body">
                            <slot>Тело окна{{ hasFooter }} {{ hasSave }} {{ hasClose }}</slot>
                        </div>

                        <div v-if="hasFooter" class="modal-footer">
                            <slot name="footer"></slot>
                        </div>
                        <div v-else-if="hasSave || hasClose" class="modal-footer">
                            <button v-if="hasClose" type="button" class="btn btn-secondary" @click="emit('close')">Закрыть</button>
                            <button v-if="hasSave" type="button" class="btn btn-outline-primary" @click="emit('save')">Применить</button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </Teleport>
</template>

<style lang="scss">
.modal-enter-from,
.modal-leave-to {
    opacity: 0;
    transform: translate(0, -50px);
}

.modal-enter-to,
.modal-leave-from {
    opacity: 1;
    transform: none;
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.15s linear, transform 0.3s ease-out;
}

///////////////////

.modal-backdrop {
    opacity: var(--bs-backdrop-opacity);
}

.modal-backdrop-enter-from,
.modal-backdrop-leave-to {
    opacity: 0 !important;
}

.modal-backdrop-enter-to,
.modal-backdrop-leave-from {
    opacity: var(--bs-backdrop-opacity);
}

.modal-backdrop-enter-active,
.modal-backdrop-leave-active {
    transition: opacity 0.15s linear;
}
</style>
