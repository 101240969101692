<script setup lang="ts">
import { computed } from 'vue'

interface Props {
    maxVisibleButtons?: number
    totalPages: number
    total: number
    perPage: number
    currentPage: number
}

const props = withDefaults(defineProps<Props>(), {
    maxVisibleButtons: 3,
})

interface Emits {
    (e: 'pagechanged', pageId: number): void
}

const emit = defineEmits<Emits>()

const startPage = computed(() => {
    if (props.currentPage === 1) {
        return 1
    }

    if (props.currentPage === props.totalPages) {
        return Math.max(props.totalPages - props.maxVisibleButtons + 1, 1)
    }

    return props.currentPage - 1
})

const endPage = computed(() => {
    return Math.min(startPage.value + props.maxVisibleButtons - 1, props.totalPages)
})

const pages = computed(() => {
    const range = []

    for (let i = startPage.value; i <= endPage.value; i += 1) {
        range.push({
            name: i,
            isDisabled: i === props.currentPage,
        })
    }

    return range
})

const isInFirstPage = computed(() => {
    return props.currentPage === 1
})

const isInLastPage = computed(() => {
    return props.currentPage === props.totalPages
})

function isPageActive(page) {
    return props.currentPage === page
}
</script>
<template>
    <ul class="pagination justify-content-center">
        <li class="btn-group pagination-item">
            <button class="btn btn-outline-primary btn-left" type="button" @click="emit('pagechanged', 1)" :disabled="isInFirstPage">
                <i class="fa-regular fa-chevrons-left"></i>
            </button>
        </li>

        <!-- Visible Buttons Start -->

        <li class="btn-group mx-1">
            <button
                class="btn btn-outline-primary btn-left"
                type="button"
                @click="emit('pagechanged', props.currentPage - 1)"
                :disabled="isInFirstPage"
            >
                <i class="fa-regular fa-chevron-left"></i>
            </button>
            <button
                v-for="page in pages"
                class="btn btn-outline-primary btn-page"
                type="button"
                @click="emit('pagechanged', page.name)"
                :disabled="page.isDisabled"
                :class="{ active: isPageActive(page.name) }"
            >
                {{ page.name }}
            </button>
            <button
                class="btn btn-outline-primary btn-right"
                type="button"
                @click="emit('pagechanged', props.currentPage + 1)"
                :disabled="isInLastPage"
            >
                <i class="fa-regular fa-chevron-right"></i>
            </button>
        </li>

        <!-- Visible Buttons End -->

        <li class="btn-group pagination-item">
            <button class="btn btn-outline-primary btn-right" type="button" @click="emit('pagechanged', props.totalPages)" :disabled="isInLastPage">
                <i class="fa-regular fa-chevrons-right"></i>
            </button>
        </li>
    </ul>
</template>

<style scoped>
.pagination {
    list-style-type: none;
}

.pagination-numbers {
    margin-left: -5px;
    margin-right: -5px;
}

.pagination-numbers .btn:first-child {
    border-left: 1px solid silver;
    border-radius: 0;
}
.pagination-numbers .btn {
    border-left: 0;
    border-radius: 0;
    width: 40px;
}

.pagination-item .btn-left {
    border-radius: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.pagination-item .btn-right {
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.pagination-item {
    display: inline-block;
}

.btn-page {
    width: 40px;
}

.pagination-item .active {
    background: unset;
    background-color: #ffba39;
    color: #ffffff;
}

.btn-right > img,
.btn-left > img {
    top: -1px;
    position: relative;
    height: 20px;
}
</style>
