import {type RouteRecordRaw } from 'vue-router'
import SchedulerView from '@/views/Settings/Scheduler/SchedulerView.vue'

export const SchedulerPage = Symbol('scheduler')

// Роуты связанные с отчетами
const routes: RouteRecordRaw[] = [
    //
    { path: '/page/gr_common/admin_main/parker_scheduler', component: SchedulerView },
]

export default routes
