import DispenserCardsView from '@/views/Cards/Dispensers/DispenserCardsView.vue'
import PrepayCardsView from '@/views/Cards/Prepay/PrepayCardsView.vue'
import {type RouteRecordRaw } from 'vue-router'

///page/gr_cards/dispensers_cards
const routes: RouteRecordRaw[] = [
    { path: '/page/gr_cards/dispensers_cards', component: DispenserCardsView },
    { path: '/page/gr_cards/prepay_cards', component: PrepayCardsView },
]

export default routes