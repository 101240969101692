import axios from '@/helpers/axios'
import { ToastSuccess, ToastError, ToastGen } from '@/helpers/toasts'

export interface CardGroupDto {
    id: number
    name: string
    block_state: 'FALSE' | 'TRUE' | 'LIMIT'
    start_time: string
    end_time: string
    valid_days: string
    tariff_id: number
    change_time: string
    valid_time: string
    places: number
    places_occup: number
    antipassback: number
}

export async function getGroupsList(): Promise<CardGroupDto[]> {
    try {
        const res = await axios.get('/api/cards/groups')
        if (res.status == 200 && !res.data.error) {
            return res.data
        }
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
    }

    return null
}
